import { getFormattedDateStr } from '../../scripts/helperFunctions';

export default class StepCommentDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.createdUtc = options.createdUtc ? getFormattedDateStr(options.createdUtc) : ''; // convert utc string to only formatted local date
    this.initials = options.initials || '';
    this.comment = options.comment || '';
  }

  stringify() {
    return JSON.stringify(this);
  }
}

import React, { useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Menu } from 'primereact/menu';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';

import StageDialog from './StageDialog';

import More from '../../images/more-vertical.png';
import Clock from '../../images/clock.png';
import Comments from '../../images/comments.png';
import StageLevel from '../../images/stage-level.png';

import { navigationUrls, insurancePathNames } from '../../constants';
import { getLongDateStr, dateDiffDays } from '../../scripts/helperFunctions';
import { StageValueNames, StepValueNames, StepValues, StageStepStatusValues } from '../../constants/StageStepConstants';

const StageStepCard = (props) => {
  const [showComments, setShowComments] = useState(false);
  const [display, setDisplay] = useState(false);
  const menu = useRef(null);

  const step = props.activeStep;
  const inPatientStages = props.location.pathname.includes(navigationUrls.patientStages.URL);

  const activeMenuItems = [
    {
      label: 'Edit Step',
      icon: '',
      command: () => {
        setDisplay(true);
      }
    }
  ];

  const toggleComments = () => {
    setShowComments(!showComments);
  }

  const openPatient = () => {
    if (inPatientStages && step.patientId > 0) {
      props.history.push(`${navigationUrls.patient.URL}/${step.patientId}`);
    }
  }

  const showPatientMenu = [
    {
      label: 'Open Patient',
      icon: '',
      command: (patientId) => {
        openPatient();
      }
    }
  ];

  const showCommentMenu = [
    {
      label: 'Show Comments',
      icon: '',
      command: () => {
        toggleComments();
      }
    }
  ];

  const hideCommentMenu = [
    {
      label: 'Hide Comments',
      icon: '',
      command: () => {
        toggleComments();
      }
    }
  ];

  const closeEdit = (reload = false) => {
    setDisplay(false);

    if (reload && props.handleReload) {
      props.handleReload();
    }
  }

  // begin setting up menu options
  let menuModel = step.statusId === StageStepStatusValues.InProgress
    ? [...activeMenuItems]
    : [];

  // add patient menu if necessary
  menuModel = inPatientStages ? menuModel.concat(showPatientMenu) : [...menuModel];

  // add either show or hide menu
  menuModel = showComments ? menuModel.concat(hideCommentMenu) : menuModel.concat(showCommentMenu);

  const daysInStep = dateDiffDays(new Date(step.createdUtc), new Date());

  return (
    <React.Fragment>
      <Card className="stage-step-card">
        <div className="row">
          <div className="col-2 d-flex flex-column align-items-center justify-content-center">
            <img src={StageLevel} alt="Stage Level" className="stages-card__icon" />
            <div className="stage-step-card__stage-name">
              {StageValueNames[step.stageId]}
            </div>
          </div>

          <div className="col-10">
            <div className="row d-flex align-items-center">
              <div className="col-1">
                {step.patientStatus &&
                  <div className="stage-step-card__active d-flex">
                    <div className="stage-step-card__inactive-dot"></div>
                    <div className="stage-step-card__active ps-2">Inactive</div>
                  </div>
                }
              </div>
              <div className="col d-flex justify-content-end">
                {step.insurancePathId > 0 &&
                  <div className="patient-path">
                    <div className="patient-path__text">
                      {insurancePathNames[step.insurancePathId]}
                    </div>
                  </div>
                }
                <div>
                  <Menu model={menuModel} popup ref={menu} id="action_menu" />
                  <div className="action-menu__button" onClick={(event) => menu.current.toggle(event)} aria-controls="action-menu" aria-haspopup>
                    <img src={More} alt="more menu" />
                  </div>
                </div>
              </div>
            </div>

            <div className="stage-step-card__step-name mb-2">
              {StepValueNames[step.stepId]}

              {step.stepId === StepValues.AppointmentScheduled &&
                <span className="stage-step-card_additional-date">
                  Appointment Date: {step.appointmentDateUtc}
                </span>
              }

              {step.stepId === StepValues.SetAsInactive && step.offBoardResumeUtc &&
                <span className="stage-step-card_additional-date">
                  Potential Resume Date: {step.offBoardResumeUtc}
                </span>
              }

              {step.stepId === StepValues.ContactSpecialtyPharmacy && step.botoxDeliveryDateUtc &&
                <span className="stage-step-card_additional-date">
                  Anticipated Botox Delivery Date: {step.botoxDeliveryDateUtc}
                </span>
              }
            </div>

            <div className="d-flex align-items-center mb-3">
              <div
                className={classNames('stage-step-card__patient-name', { 'cursor-pointer': inPatientStages })}
                onClick={openPatient}>
                {step.patientName}
              </div>
              <div className="stage-step-card__patient-dob">
                DOB: {step.patientDateOfBirthUtc}
              </div>
            </div>

            <div className="d-flex align-items-center mb-3">
              {step.statusId === StageStepStatusValues.InProgress &&
                <React.Fragment>
                  <img src={Clock} alt="Days in stage" />
                  <div className="stage-step-card__days">
                    {daysInStep} {`Day${daysInStep === 1 ? '' : 's'} in Stage`}
                  </div>
                </React.Fragment>
              }
              <img src={Comments} alt="Days in stage" />
              <div className="stage-step-card__comments" onClick={toggleComments}>
                {step.comments.length}{` Comment${step.comments.length === 1 ? '' : 's'}`}
              </div>
            </div>
          </div>
        </div>

        {showComments &&
          step.comments.map(dto => (
            <div className="row">
              <div className="col-10 offset-2">
                <div className="step-comment__wrapper">
                  <div className="d-flex align-items-center mb-2">
                    <div className="step-comment__initials d-flex justify-content-center align-items-center">
                      {dto.initials}
                    </div>
                    <div className="step-comment__date ms-4">
                      {getLongDateStr(dto.createdUtc)}
                    </div>
                    <div className="step-comment__comment ms-4">
                      {dto.comment}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }

      </Card >

      <Dialog header="Edit Step" footer={<div></div>} visible={display} style={{ width: '50vw' }} modal onHide={closeEdit}>
        <StageDialog closeEdit={closeEdit} stageStepId={step.id} />
      </Dialog>

    </React.Fragment>
  );
}

export default withRouter(StageStepCard);

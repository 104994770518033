import { getFormattedDateStr } from '../../scripts/helperFunctions';
import StepCommentDto from './StepCommentDto';

export default class StageStepDisplayDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.patientId = options.patientId || 0;
    this.patientStatus = options.patientStatus || '';
    this.createdUtc = options.createdUtc || '';
    this.statusId = options.statusId || 0;
    this.stageId = options.stageId || 0;
    this.stepId = options.stepId || 0;
    this.patientName = options.patientName || '';
    this.patientDateOfBirthUtc = options.patientDateOfBirthUtc ? getFormattedDateStr(options.patientDateOfBirthUtc) : ''; // convert utc string to only formatted local date
    this.appointmentDateUtc = options.appointmentDateUtc ? getFormattedDateStr(options.appointmentDateUtc) : ''; // convert utc string to only formatted local date
    this.offBoardResumeUtc = options.offBoardResumeUtc ? getFormattedDateStr(options.offBoardResumeUtc) : ''; // convert utc string to only formatted local date
    this.botoxDeliveryDateUtc = options.botoxDeliveryDateUtc ? getFormattedDateStr(options.botoxDeliveryDateUtc) : ''; // convert utc string to only formatted local date
    this.insurancePathId = options.insurancePathId || 0;
    this.comments = options.comments ? options.comments.map(dto => new StepCommentDto(dto)) : [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}

export default class EditOfficeDto {
  constructor(options = {}) {
    this.id = options.officeId || 0;
    this.officeName = options.officeName || '';
    this.isActive = options.hasOwnProperty('isActive') ? options.isActive : true;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

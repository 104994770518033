import { getFormattedDateStr } from '../../scripts/helperFunctions';

export default class PatientNeedsPriorAuth {
  constructor(options = {}) {
    this.patientId = options.patientId || 0;
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.expirationDateUtc = options.expirationDateUtc ? getFormattedDateStr(options.expirationDateUtc) : ''; // convert utc string to only formatted local date

    this.displayName = `${this.firstName} ${this.lastName}`;
  }

  toJSON() {
    const data = { ...this };
    data.expirationDateUtc = new Date(this.expirationDateUtc).toISOString();

    return data;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

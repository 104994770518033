import { apiCall } from './api.base.js';

import PatientDto from '../DTOs/patient/PatientDto';
import InsuranceInfoDto from '../DTOs/patient/InsuranceInfoDto';
import SearchPatientListDto from '../DTOs/patient/SearchPatientListDto';

export default {
  /**
   * Requests system Patient' info
   * @param {Object} dto
   * @returns {Promise<PatientDto]>} - @see PatientDto
   */
  getPatient(patientId) {
    return apiCall(`patient?patientId=${patientId}`, {
      responseType: PatientDto
    });
  },

  /**
   * Create Patient
   * @param {any} dto
   */
  createPatient(dto) {
    return apiCall('patient', { method: 'POST', body: dto });
  },

  /**
   * Edit Patient
   * @param {any} dto
   */
  editPatient(dto) {
    return apiCall(`patient`, { method: 'PUT', body: dto });
  },

  /**
   * Requests active insurance and pharmacy data
   * @param {Object} dto
   * @returns {Promise<InsuranceInfoDto]>} - @see InsuranceInfoDto
   */
  getInsuranceInfo() {
    return apiCall(`patient/insuranceinfo`, {
      responseType: InsuranceInfoDto
    });
  },

  /**
   * Searches patients by first/last name
   * @param {Object} dto
   * @returns {Promise<SearchPatientListDto>} - @see SearchPatientListDto
   */
  searchPatient(dto) {
    return apiCall(`patient/search`, {
      method: 'POST',
      body: dto,
      responseType: SearchPatientListDto
    });
  }
}

import { insuranceTypes } from '../../constants';

export default class InsuranceDetailDto {
  constructor(options = {}) {
    this.insuranceId = options.id || 0;
    this.insuranceName = options.insuranceName || '';
    this.insuranceType = options.insuranceType || insuranceTypes.Insurance;
    this.isActive = options.hasOwnProperty('isActive') ? options.isActive : true;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

export default class SearchPatientResultDto {
  constructor(options = {}) {
    this.displayName = options.displayName || '';
    this.patientId = options.patientId || 0;
  }


  stringify() {
    return JSON.stringify(this);
  }
}

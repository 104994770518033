import React from 'react';
import { Redirect } from 'react-router';
import { Auth0Context } from '../shared/Auth0';

const HomeRedirect = ({ rolePath }) => (
  <Auth0Context.Consumer>
    {({ isAuthenticated, loading }) => {
      if (loading) {
        return null;
      }
      if (isAuthenticated) {
        return <Redirect to={{
          pathname: rolePath
        }} />;
      } else {
        return <Redirect to={{
          pathname: "/"
        }} />;
      }
    }}
  </Auth0Context.Consumer>
);

export default HomeRedirect;

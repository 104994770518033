// cannot nest these files within a subfolder.  Webpack cannot find them as nested files
//import { default as admin } from './api.admin';
import { default as authorization } from './api.authorization';
import { default as user } from './api.user';
import { default as office } from './api.office';
import { default as pharmacy } from './api.pharmacy';
import { default as insurance } from './api.insurance';
import { default as patient } from './api.patient';
import { default as stage } from './api.stage';
import { default as dashboard } from './api.dashboard';

export default {
  ...authorization,
  ...user,
  ...office,
  ...pharmacy,
  ...insurance,
  ...patient,
  ...stage,
  ...dashboard
}

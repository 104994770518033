import React from 'react';
import { Controller } from 'react-hook-form';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { InputMask } from 'primereact/inputmask';
import { classNames } from 'primereact/utils';

import { getFormErrorMessage } from '../../../scripts/helperFunctions';
import { OffBoardOptions } from '../../../constants/StageStepConstants';

const SetAsInactive = ({ errors, control, watch }) => {

  const watchOffBoardReason = watch('offBoardReason');
  const watchOffBoardResume = watch('offBoardResume');

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col-6">
          <Controller name="offBoardReason" control={control}
            rules={{ required: 'Reason is required' }}
            render={({ field: { onChange, value } }) => (
              <React.Fragment>
                <div className="field-label">
                  Reason
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="reasonMoved" name="offboardRadio" value={OffBoardOptions.Moved} onChange={onChange} checked={value === OffBoardOptions.Moved} />
                  <label htmlFor="reasonMoved" className="ms-3 radio-label">Moved</label>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="reasonInsurance" name="offboardRadio" value={OffBoardOptions.Insurance} onChange={onChange} checked={value === OffBoardOptions.Insurance} />
                  <label htmlFor="reasonInsurance" className="ms-3 radio-label">Lack of Insurance Coverage</label>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="reasonOther" name="offboardRadio" value={OffBoardOptions.Other} onChange={onChange} checked={value === OffBoardOptions.Other} />
                  <label htmlFor="reasonOther" className="ms-3 radio-label">Other</label>
                </div>
              </React.Fragment>
            )} />
          {getFormErrorMessage(errors, 'offBoardReason')}
        </div>
        {watchOffBoardReason === OffBoardOptions.Other &&
          <div className="col-12 mt-2">
            <Controller name="offBoardOther" control={control}
              rules={{
                required: 'Reason Other is required',
                maxLength: { value: 100, message: 'Reason Other cannot exceed 100 characters' }
              }}
              render={({ field, fieldState }) => (
                <InputText id={field.offBoardOther} placeholder="Enter a reason" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
              )} />
            {getFormErrorMessage(errors, 'offBoardOther')}
          </div>
        }
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <div className="field-label">Temporary?</div>
          <Controller name="offBoardResume" control={control} render={({ field: { onChange, value } }) => (
            <div className="mt-2 d-flex align-items-center">
              <Checkbox inputId="offBoardResume" onChange={onChange} checked={value === true} />
              <label htmlFor="offBoardResume" className="ms-3 radio-label">Yes</label>
            </div>
          )} />
        </div>
        <div className="col-6">
          {watchOffBoardResume &&
            <React.Fragment>
              <label htmlFor="offBoardResumeUtc" className={classNames('field-label', { 'p-error': errors.offBoardResumeUtc })}>Potential Resume Date</label>
              <Controller name="offBoardResumeUtc" control={control}
                rules={{
                  required: 'Potential Resume Date is required',
                  pattern: { value: /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/i, message: 'Invalid date format e.g. mm/dd/yyyy' }
                }}
                render={({ field, fieldState }) => (
                  <InputMask id={field.offBoardResumeUtc} mask="99/99/9999" placeholder="mm/dd/yyyy" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
              {getFormErrorMessage(errors, 'offBoardResumeUtc')}
            </React.Fragment>
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default SetAsInactive;

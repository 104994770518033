import PharmacyDetailDto from './PharmacyDetailDto';

export default class PharmacyListDto {
  constructor(options = []) {
    this.allpharmacies = options.map(dto => new PharmacyDetailDto(dto));
  }

  stringify() {
    return JSON.stringify(this);
  }
}

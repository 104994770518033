import Auth0Base from '../scripts/auth0Base';
import { ADMIN_REQUEST_URL } from '../constants';

const API_URL = process.env.REACT_APP_API_URL;

export class HttpError extends Error {
  constructor(status, payload) {
    let message = `HttpError ${status}`;
    if (payload) {
      if (payload.message) {
        message += `: ${payload.message}`;
      } else {
        message += `: ${JSON.stringify(payload)}`;
      }
    }

    super(message);
    this.payload = payload;
    this.status = status;
  }
}

/**
 * Private helper method for sending a request to the API
 *
 * @private
 * @param {string} url = the url endpoint *
 * @param {object} requestOptions = {is404Success:bool, method:Rest Type, body: object}
 * @returns {Promise<Response>} A promise object which resolves with the response of the request
 */
export const fetchData = async function (url, requestOptions = {}) {
  const headers = await getHeaders(url, requestOptions);

  const is404Success = requestOptions.is404Success;
  const useRawResponse = requestOptions.useRawResponse;

  // Automatic response wrapping
  let wrapper = null;
  if (requestOptions.wrapper) {
    wrapper = requestOptions.wrapper;
    delete requestOptions.wrapper;
  } else if (requestOptions.responseType) {
    const Dto = requestOptions.responseType;
    wrapper = result => new Dto(result);
    delete requestOptions.responseType;
  }

  // Automatic DTO stringify
  if (requestOptions.body && requestOptions.body.stringify) {
    requestOptions.body = requestOptions.body.stringify();
  }

  try {
    const response = await fetch(url, { headers, ...requestOptions });

    let parsedResponse = null;
    let hasParsedResponse = false;
    try {
      parsedResponse = useRawResponse ? response : await response.json();
      hasParsedResponse = true;
    } catch (e) { }

    if ((is404Success ? response.status !== 404 : true) &&
      response.status >= 400 &&
      response.status < 600) {
      switch (response.status) {
        case 400:
        case 401:
        case 404:
        case 409:
        case 500:
          throw new HttpError(response.status, parsedResponse);
        default:
          throw new HttpError(response.status);
      }
    }

    const hasResult = response.status !== 404;

    if (wrapper && (!is404Success || hasResult)) {
      try {
        parsedResponse = await wrapper(parsedResponse);
      } catch (err) {
        console.error(`Error attempting to wrap response for ${url}: ${err}`);
        throw err;
      }
    }

    const safeResponse = hasParsedResponse ? parsedResponse : {};
    if (is404Success) {
      return [hasResult, safeResponse];
    } else {
      return safeResponse;
    }
  } catch (err) {
    console.error(`Error attempting to call api with url ${url}: ${err}`);
    throw err;
  }
};

/**
 * Private methods to set necessary request headers used by fetch
 * @private
 *
 * @param {string} url = the url endpoint *
 * @param {object} requestOptions = {is404Success:bool, method:Rest Type, body: object}
 * @returns {Promise<Response>} A promise object which resolves with the necessary header information
 */
const getHeaders = async (url, requestOptions) => {
  let headers;
  if (url === API_URL + ADMIN_REQUEST_URL && requestOptions.method === 'POST') {
    headers = new Headers();
    headers.append('Content-Type', 'application/json');
  } else {
    headers = await Auth0Base.getHeaders();
  }

  return headers;
};

/**
 * Makes a request to the API and returns the data as a JSON object (Same as calling response.json())
 * 
 * @private
 * @param {String} url - The relative url
 * @param {Object} requestOptions - Any request options optional
 * @returns {Promise<Object>} - The object returned from the api call or an empty object if the api call returns nothing
 */
export const apiCall = (url, requestOptions) => {
  const fullUrl = `${API_URL}/${url}`;
  return fetchData(fullUrl, requestOptions);
};

import InsuranceDetailDto from './InsuranceDetailDto';

export default class InsuranceListDto {
  constructor(options = []) {
    this.allinsurance = options.map(dto => new InsuranceDetailDto(dto));
  }

  stringify() {
    return JSON.stringify(this);
  }
}

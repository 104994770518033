import { apiCall } from './api.base.js';

import PharmacyListDto from '../DTOs/pharmacy/PharmacyListDto';

export default {
  /**
   * Requests system pharmacy info
   * @param {Object} dto
   * @returns {Promise<[hasResult: boolean, result: PharmacyListDto]>} - @see PharmacyListDto
   */
  getAllPharmacies() {
    return apiCall('pharmacy', {
      responseType: PharmacyListDto,
      is404Success: true
    });
  },

  /**
   * Create Pharmacy
   * @param {any} dto
   */
  createPharmacy(dto) {
    return apiCall('pharmacy', { method: 'POST', body: dto });
  },

  /**
   * Edit Pharmacy
   * @param {any} dto
   */
  editPharmacy(id, dto) {
    return apiCall(`pharmacy/${id}`, { method: 'PUT', body: dto });
  }
}

import UserDetailDto from './UserDetailDto';

export default class UserListDto {
  constructor(options = []) {
    this.allusers = options.map(dto => new UserDetailDto(dto));
  }

  stringify() {
    return JSON.stringify(this);
  }
}

import DashboardPatientDto from './DashboardPatientDto';
import PatientNeedsPriorAuth from './PatientNeedsPriorAuth';

export default class DashboardDto {
  constructor(options = {}) {
    this.requestPriorAuthCount = options.requestPriorAuthCount || 0;
    this.priorAuthPendingCount = options.priorAuthPendingCount || 0;
    this.botoxProcurementCount = options.botoxProcurementCount || 0;
    this.ongoingAppointmentsCount = options.ongoingAppointmentsCount || 0;
    this.requestPriorAuth = options.requestPriorAuth ? options.requestPriorAuth.map(dto => new DashboardPatientDto(dto)) : [];
    this.priorAuthPending = options.priorAuthPending ? options.priorAuthPending.map(dto => new DashboardPatientDto(dto)) : [];
    this.botoxProcurement = options.botoxProcurement ? options.botoxProcurement.map(dto => new DashboardPatientDto(dto)) : [];
    this.ongoingAppointments = options.ongoingAppointments ? options.ongoingAppointments.map(dto => new DashboardPatientDto(dto)) : [];
    this.needsRenewal = options.needsRenewal ? options.needsRenewal.map(dto => new PatientNeedsPriorAuth(dto)) : [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}

import { apiCall } from './api.base.js';

import UserInfoDto from '../DTOs/store/UserInfoDto';

/**
 * Get the information for the currently logged in user.
 * @returns {Promise<UserInfoDto>}
 */
export default {
  getCurrentUser() {
    return apiCall(`authorization/current`, {
      responseType: UserInfoDto
    });
  }
}

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';

import NewPatientDto from '../../DTOs/patient/NewPatientDto';

import { getFormErrorMessage } from '../../scripts/helperFunctions';
import { message } from '../shared/Message';
import api from '../../scripts/api';
import { variantType } from '../../constants/MessageConstants';
import {
  StepValues,
  StepValueNames,
  StageValues,
  StageValueNames,
  StageStepStatusValues
} from '../../constants/StageStepConstants'

import PlusSquare from '../../images/plus-square.png';

const PatientFirstStep = (props) => {
  const [showComments, setShowComments] = useState(false);

  const defaultValues = {
    ...props.patientInfo,
    statusId: StageStepStatusValues.InProgress,
    stageId: props.patientInfo.buyAndBill ? StageValues.ScheduleAppointments : StageValues.RequestPriorAuth,
    stepId: props.patientInfo.buyAndBill ? StepValues.ScheduleAppointment : StepValues.SubmitRxPriorAuth
  }

  const { control, formState: { errors }, handleSubmit, getValues } = useForm({ shouldFocusError: false, defaultValues });

  const onSubmit = async (data) => {
    console.log(data);

    try {
      const patientId = await api.createPatient(new NewPatientDto({ ...props.patientInfo, ...data }));
      props.closeEdit(false, patientId);
    } catch (e) {
      console.log(e.payload);
      const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
      message.createMessageObject(msg, variantType.error);
    }
  }

  const toggleComments = () => {
    setShowComments(!showComments);
  }

  const requestPriorAuth = () => {
    return (
      <React.Fragment>
        <div className="row  mb-3">
          <div className="col-6">
            <div className="field-label">Stage</div>
            {StageValueNames[StageValues.RequestPriorAuth]}
          </div>
          <div className="col-6">
            <div className="field-label">Step</div>
            {StepValueNames[StepValues.SubmitRxPriorAuth]}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-6">
            <Controller name="statusId" control={control}
              render={() => (
                <React.Fragment>
                  <div className="field-label">
                    Step Status
                  </div>
                  <div className="mt-2 d-flex align-items-center">
                    <RadioButton inputId="statusInProgress" name="status" value={StageStepStatusValues.InProgress} checked={true} />
                    <label htmlFor="status" className="ms-3 radio-label">In Progress</label>
                  </div>
                </React.Fragment>
              )} />
          </div>
          <div className="col-6">
            <label htmlFor="dateSubmittedUtc" className={classNames('field-label', { 'p-error': errors.dateSubmittedUtc })}>Date Created</label>
            <Controller name="dateSubmittedUtc" control={control}
              rules={{
                required: 'Date Created is required',
                pattern: { value: /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/i, message: 'Invalid date format e.g. mm/dd/yyyy' }
              }}
              render={({ field, fieldState }) => (
                <InputMask id={field.dateSubmittedUtc} mask="99/99/9999" placeholder="mm/dd/yyyy" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
              )} />
            {getFormErrorMessage(errors, 'dateSubmittedUtc')}
          </div>
        </div>
      </React.Fragment>
    );
  }

  const scheduleAppointment = () => {
    return (
      <React.Fragment>
        <div className="row mb-3">
          <div className="col-6">
            <div className="field-label">Stage</div>
            {StageValueNames[StageValues.ScheduleAppointments]}
          </div>
          <div className="col-6">
            <div className="field-label">Step</div>
            {StepValueNames[StepValues.ScheduleAppointment]}
          </div>
        </div>
        
        
        <div className="row mb-3">
          <div className="col-6">
            <Controller name="statusId" control={control}
              render={() => (
                <React.Fragment>
                  <div className="field-label">
                    Step Status
                  </div>
                  <div className="mt-2 d-flex align-items-center">
                    <RadioButton inputId="statusInProgress" name="status" value={StageStepStatusValues.InProgress} checked={true} />
                    <label htmlFor="status" className="ms-3 radio-label">In Progress</label>
                  </div>
                </React.Fragment>
              )} />
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

      {props.patientInfo.buyAndBill
        ? scheduleAppointment()
        : requestPriorAuth()
      }

      <div className="row mb-2">
        <div className="col d-flex justify-content-end" onClick={() => toggleComments()}>
          <div className="add-comment me-4">
            <img src={PlusSquare} alt="Add comments" />
          </div>
          <div className="add-comment__text">
            Add/Edit Comments
          </div>
        </div>
      </div>

      {showComments &&
        <div className="row">
          <div className="col">
            <label htmlFor="stepComment" className="field-label">Comments</label>
            <Controller name="stepComment" control={control} render={({ field }) => (
              <InputTextarea id={field.stepComment} placeholder="Step Comments" {...field} />
            )} />
          </div>
        </div>
      }

      <div className="row mt-4">
        <div className="col-6 d-flex justify-content-start">
          <button type="button" className="button-cancel"
            onClick={() => props.handleBack({ ...props.patientInfo, ...getValues() })}>Back</button>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <button className="button-primary" type="submit">Create New Patient</button>
        </div>
      </div>

    </form>
  );
}

export default withRouter(PatientFirstStep);

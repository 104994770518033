import api from '../scripts/api';

export const beginInitialization = 'BEGIN_INITIALIZATION';
export const endInitialization = 'END_INITIALIZATION';

export const requestCurrentUserType = 'REQUEST_CURRENT_USER';
export const receiveCurrentUserSuccessType = 'RECEIVE_CURRENT_USER_SUCCESS';
export const receiveCurrentUserFailureType = 'RECEIVE_CURRENT_USER_FAILURE';

export const initialState = {
  id: 0,
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  roles: [],
  isPopulated: false,
  initializing: false
};

export const actionUserCreators = {
  initializeUser: () => async (dispatch, getState) => {
    dispatch({ type: beginInitialization });
    await dispatch(actionUserCreators.lookupUser());
    dispatch({ type: endInitialization });
  },

  lookupUser: () => async (dispatch, getState) => {
    if (getState().user.isPopulated) {
      // Don't need to get the data again
      return;
    }

    dispatch({ type: requestCurrentUserType });
    let userInfo;
    try {
      userInfo = await api.getCurrentUser();
    } catch (err) {
      dispatch({ type: receiveCurrentUserFailureType });
      return;
    }

    dispatch({
      type: receiveCurrentUserSuccessType,
      payload: {
        id: userInfo.id,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        fullName: userInfo.fullName,
        email: userInfo.email,
        roles: userInfo.roles
      }
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  // Initialization
  if (action.type === beginInitialization) {
    return {
      ...state,
      initializing: true
    };
  }
  if (action.type === endInitialization) {
    return {
      ...state,
      initializing: false
    };
  }

  // requesting user information
  if (action.type === requestCurrentUserType) {
    return {
      ...state,
      initializing: true,
      isPopulated: false
    };
  }
  if (action.type === receiveCurrentUserSuccessType) {
    return {
      ...state,
      ...action.payload,
      isPopulated: true,
      initializing: false
    };
  }
  if (action.type === receiveCurrentUserFailureType) {
    return {
      ...state,
      isPopulated: false,
      initializing: false
    };
  }

  return state;
};

/**
 * Creates a "Lock" for async JS code.
 * */
export class Lock {
  constructor() {
    this.locked = false;
    this.unlockQueue = [];
  }

  /**
   * Acquire the lock.
   * @param {any} block Wait for the lock to acquire. Otherwise return false.
   */
  acquire = async (block = true) => {
    if (this.locked) {
      if (block) {
        await new Promise(resolve => {
          this.unlockQueue.unshift(resolve);
        });
      } else {
        return false;
      }
    }
    this.locked = true;
    return true;
  }

  /**
   * Release the lock
   * */
  release = () => {
    if (this.unlockQueue.length > 0) {
      this.unlockQueue.pop()();
    } else {
      this.locked = false;
    }
  }

  hasQueuedItems = () => {
    return this.unlockQueue.length > 0;
  }
}

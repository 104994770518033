import React from 'react'
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputMask } from 'primereact/inputmask';
import { classNames } from 'primereact/utils';

import { message } from '../shared/Message';
import api from '../../scripts/api';
import { variantType } from '../../constants/MessageConstants';

import EditPharmacyDto from '../../DTOs/pharmacy/EditPharmacyDto';


const PharmacyDialog = (props) => {
  const defaultValues = props.currentPharmacy || {
    pharmacyName: '',
    phone: '',
    isActive: true
  }

  const { control, formState: { errors }, handleSubmit } = useForm({ shouldFocusError: false, defaultValues });

  const onSubmit = async (data) => {
    console.log(data);

    const pharmacyId = props.currentPharmacy ? props.currentPharmacy.pharmacyId : 0;
    const pharmacyData = new EditPharmacyDto({ pharmacyId: pharmacyId, ...data });  // must cast the data object into a dto so stringify is available

    try {
      if (pharmacyId > 0) {
        await api.editPharmacy(pharmacyId, pharmacyData);
      } else {
        await api.createPharmacy(pharmacyData);
      }

    } catch (e) {
      console.log(e.payload);
      const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
      message.createMessageObject(msg, variantType.error);
    }

    props.closeEdit(true);
  };

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
      <div className="mb-2">
        <label htmlFor="pharmacyName" className={classNames('field-label', { 'p-error': errors.pharmacyName })}>Pharmacy Name</label>
        <Controller name="pharmacyName" control={control} rules={{ required: 'Pharmacy Name is required' }} render={({ field, fieldState }) => (
          <InputText id={field.pharmacyName} placeholder="New Pharmacy" {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
        )} />
        {getFormErrorMessage('pharmacyName')}
      </div>

      <div className="mb-2">
        <label htmlFor="phone" className={classNames('field-label', { 'p-error': errors.phone })}>Phone Number</label>
        <Controller name="phone" control={control}
          rules={{
            required: 'Phone Number is required',
            pattern: { value: /^\(\d{3}\) \d{3}-\d{4}$/i, message: 'Invalid format e.g. (999) 999-9999' }
          }}
          render={({ field, fieldState }) => (
            <InputMask id={field.phone} mask="(999) 999-9999" placeholder="(999) 999-9999" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
          )} />
        {getFormErrorMessage('phone')}
      </div>

      <div className="row mb-2">
        <div className="col-6">
          <div className="field-label">Status</div>
          <Controller name="isActive" control={control}
            render={({ field: { onChange, value } }) => (
              <div className="d-flex">
                <label htmlFor="isActive" className="me-3 switch-label">Active</label>
                <InputSwitch id="isActive" checked={value} onChange={onChange} />
              </div>
            )} />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6 d-flex justify-content-start">
          <button type="button" className="button-cancel" onClick={() => props.closeEdit()}>Cancel</button>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <button className="button-primary" type="submit">{props.currentPharmacy ? 'Save Pharmacy' : 'Add New Pharmacy'}</button>
        </div>
      </div>
    </form >
  );
}

export default PharmacyDialog;

import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';

import PAApproved from './PAApproved';
import PADenied from './PADenied';

import { getFormErrorMessage } from '../../../scripts/helperFunctions';
import { DecisionTypes, InsurancePathValues } from '../../../constants/StageStepConstants';

const PriorAuthPending = ({ errors, control, setValue, decisionType, doValidations }) => {
  const [decision, setDecision] = useState(decisionType); // need to pass the existing decision

  const getDecisionForm = () => {
    // no extra fields required for appealed status
    if (decision === DecisionTypes.Approved) {
      return <PAApproved errors={errors} control={control} doValidations={doValidations} />;
    } else if (decision === DecisionTypes.Declined) {
      return <PADenied errors={errors} control={control} doValidations={doValidations} />;
    } else {
      return null;
    }
  }

  const selectDecision = (decisionId) => {
    console.log(decisionId);
    setValue('priorAuthDecisionId', decisionId, { shouldDirty: true });
    setDecision(decisionId);
  }

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col-6">
          <Controller name="priorAuthDecisionId" control={control}
            rules={doValidations
              ? { required: 'Insurance Decision is required' }
              : null
            }
            render={({ field: { value } }) => (
              <React.Fragment>
                <div className={classNames('field-label', { 'p-error': errors.priorAuthDecisionId })}>
                  Insurance Decision
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="approved" name="decision" value={DecisionTypes.Approved} onChange={(e) => selectDecision(e.value)} checked={value === DecisionTypes.Approved} />
                  <label htmlFor="approved" className={classNames('ms-3 radio-label', { 'p-error': errors.priorAuthDecisionId })}>Approved</label>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="denied" name="decision" value={DecisionTypes.Declined} onChange={(e) => selectDecision(e.value)} checked={value === DecisionTypes.Declined} />
                  <label htmlFor="denied" className={classNames('ms-3 radio-label', { 'p-error': errors.priorAuthDecisionId })}>Denied</label>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="appealed" name="decision" value={DecisionTypes.Appealed} onChange={(e) => selectDecision(e.value)} checked={value === DecisionTypes.Appealed} />
                  <label htmlFor="appealed" className={classNames('ms-3 radio-label', { 'p-error': errors.priorAuthDecisionId })}>Pending Appeal/Add'l Documentation</label>
                </div>
              </React.Fragment>
            )} />
          {getFormErrorMessage(errors, 'priorAuthDecisionId')}
        </div>
      </div>

      {getDecisionForm()}

      <div className="row mb-3">
        <div className="col-6">
          <Controller name="insurancePathId" control={control}
            render={({ field: { onChange, value } }) => (
              <React.Fragment>
                <div className="field-label">
                  Insurance Path
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="pathRx" name="path" value={InsurancePathValues.Prescription} onChange={onChange} checked={value === InsurancePathValues.Prescription} />
                  <label htmlFor="pathRx" className="ms-3 radio-label">Pharmacy</label>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="pathHealth" name="path" value={InsurancePathValues.Health} onChange={onChange} checked={value === InsurancePathValues.Health} />
                  <label htmlFor="pathHealth" className="ms-3 radio-label">Medical</label>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="pathBuy" name="path" value={InsurancePathValues.BuyAndBill} onChange={onChange} checked={value === InsurancePathValues.BuyAndBill} />
                  <label htmlFor="pathBuy" className="ms-3 radio-label">Buy and Bill</label>
                </div>
              </React.Fragment>
            )} />
        </div>
      </div>

    </React.Fragment>
  );
}

export default PriorAuthPending;

import React, { useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';

import CompleteStep from './CompleteStep';
import NewStep from './NewStep';

import { message } from '../shared/Message';
import LoadingSpinner from '../shared/LoadingSpinner';

import api from '../../scripts/api';
import { variantType } from '../../constants/MessageConstants';

import AddNewStageStepDto from '../../DTOs/stageStep/AddNewStageStepDto';

const StageDialog = (props) => {
  const [showCompleteStep, setShowCompleteStep] = useState(true);
  const [showNewStep, setShowNewStep] = useState(false);
  const [completeStepInfo, setCompleteStepInfo] = useState(null);
  const [newStepInfo, setNewStepInfo] = useState(new AddNewStageStepDto());

  useEffect(() => {
    const getStageStep = async () => {
      try {
        const result = await api.getStageStep(props.stageStepId);
        setCompleteStepInfo(result);
      } catch (e) {
        const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
        message.createMessageObject(msg, variantType.error);
      }
    };

    getStageStep();
  }, []);

  const handleCompleteNext = (stepInfo) => {
    console.log(stepInfo);
    setCompleteStepInfo({ ...completeStepInfo, ...stepInfo });
    setShowCompleteStep(false);
    setShowNewStep(true);
  }

  const handleNewBack = (stepInfo) => {
    setNewStepInfo({ ...newStepInfo, ...stepInfo });
    setShowCompleteStep(true);
    setShowNewStep(false);
  }

  const getDialogBody = () => {
    if (showCompleteStep) {
      return <CompleteStep stepInfo={completeStepInfo} closeEdit={props.closeEdit}
        handleNext={handleCompleteNext} />;
    } else {
      return <NewStep stepInfo={newStepInfo} closeEdit={props.closeEdit}
        completedStageId={completeStepInfo.stageId} completedStepId={completeStepInfo.stepId}
        patientId={completeStepInfo.patientId} handleBack={handleNewBack}
        completeStepInfo={completeStepInfo} />;
    }
  };

  if (!completeStepInfo)
    return <LoadingSpinner />;

  return (
    <React.Fragment>
      <div className="row stage-dialog__wrapper">
        <div className="col d-flex align-self-center">
          <div className={classNames('d-flex justify-content-center align-items-center stage-step',
            { 'stage-step__active': showCompleteStep })}>
            1
          </div>
          <div className={classNames('ms-3 stage-step__text', { 'stage-step__text-active': showCompleteStep })}>
            Step Information
          </div>
          <div className={classNames('d-flex justify-content-center align-items-center stage-step ms-5',
            { 'stage-step__active': showNewStep })}>
            2
          </div>
          <div className={classNames('ms-3 stage-step__text', { 'stage-step__text-active': showNewStep })}>
            Add New Step
          </div>
        </div>
      </div>
      {getDialogBody()}
    </React.Fragment>
  );
}

export default StageDialog;

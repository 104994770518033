export default class UserDetailDto {
  constructor(options = {}) {
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.emailAddress = options.emailAddress || '';
    this.userId = options.userId || 0;
    this.roleName = options.roleName || '';
    this.role = options.role || 0;
    this.isActive = options.hasOwnProperty('isActive') ? options.isActive : true;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

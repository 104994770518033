import { apiCall } from './api.base.js';

import DashboardDto from '../DTOs/dashboard/DashboardDto';

export default {
  /**
   * Requests dashboard data
   * @returns {Promise<DashboardDto]>} - @see DashboardDto
   */
  getDashboardData() {
    return apiCall(`dashboard`, {
      responseType: DashboardDto
    });
  },
}

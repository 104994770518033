import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';

import PageHeader from './PageHeader';
import LoadingSpinner from '../shared/LoadingSpinner';
import PatientDialog from '../patient/PatientDialog';
import StageStepCard from '../stages/StageStepCard';
import LoadMore from '../shared/LoadMore';
import { message } from '../shared/Message';

import { sortOrder, stepSortCol, navigationUrls } from '../../constants';
import { StageValues, StepValueNames } from '../../constants/StageStepConstants';
import { variantType } from '../../constants/MessageConstants';

import PatientStagesQueryDto from '../../DTOs/stageStep/PatientStagesQueryDto';

import Sort from '../../images/sort.png';
import Filter from '../../images/filter.png';
import NewPatient from '../../images/new-patient.png';

import api from '../../scripts/api';

import '../../css/stages.css';

const useDashboardStage = () => {
  const dashboardStage = localStorage.getItem('dashboardStage');

  if (dashboardStage) {
    localStorage.removeItem('dashboardStage');
    return new PatientStagesQueryDto({
      allStages: false,
      stage: parseInt(dashboardStage, 10)
    });
  } else {
    return new PatientStagesQueryDto();
  }
}

const getStepValueFilterOptions = (filterStep) => {
  let result = [];

  Object.entries(StepValueNames).forEach(entry => {
    const [key, value] = entry;
    result.push({
      label: value,
      command: () => {
        filterStep(key);
      }
    });
  });

  return result;
}

// ReSharper disable once InconsistentNaming
const PatientStages = (props) => {
  const [stageQuery, setStageQuery] = useState(useDashboardStage());
  const [stageSteps, setStageSteps] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);  // page number is zero indexed
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const sortMenu = useRef(null);
  const filterMenu = useRef(null);

  const searchStageSteps = async (reload) => {
    if (reload) {
      setLoading(true);
    }

    try {
      const query = new PatientStagesQueryDto({
        ...stageQuery,
        currentPage: reload ? 0 : currentPage
      });

      const result = await api.searchPatientStages(query);

      setHasMore(result.hasMore);
      if (!reload) {
        setCurrentPage(currentPage + 1);
        setStageSteps(stageSteps.concat(result.patientStages)); // append to current steps
      } else {
        setCurrentPage(0);
        setStageSteps(result.patientStages);  // replace current steps
      }
    } catch (e) {
      const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
      message.createMessageObject(msg, variantType.error);
    }

    if (reload) {
      setLoading(false);
    }
  }

  const reloadPage = () => {
    searchStageSteps(true);
  }

  const loadMore = () => {
    searchStageSteps(false);
  }

  useEffect(() => {
    searchStageSteps(false);
  }, [stageQuery]);

  const addPatient = () => {
    setDisplay(true);
  }

  const closeEdit = (reload = false, patientId) => {
    setDisplay(false);

    if (patientId) {
      props.history.push(`${navigationUrls.patient.URL}/${patientId}`);
    } else if (reload) {
      //getPatient();
    }
  }

  const setAllStages = () => {
    setStageSteps([]);
    setCurrentPage(0);
    setStageQuery({
      ...stageQuery,
      allStages: true,
      stage: null
    });
  }

  const setStageValue = (stageValue) => {
    setStageSteps([]);
    setCurrentPage(0);
    setStageQuery({
      ...stageQuery,
      allStages: false,
      stage: stageValue
    });
  }

  const addOptions = {
    text: 'Add Patient',
    iconSrc: NewPatient,
    iconAlt: 'Add Patient',
    command: addPatient,
    className: 'stages__new-button'
  }

  const filterStep = (stepFilter) => {
    setStageSteps([]);
    setCurrentPage(0);
    if (stepFilter) {
      stepFilter = parseInt(stepFilter, 10);
    }

    setStageQuery({
      ...stageQuery,
      stepFilter: stepFilter
    });
  }

  const sortStep = (col, dir) => {
    setStageSteps([]);
    setCurrentPage(0);

    if (col) {
      col = parseInt(col, 10);
    }

    if (dir) {
      dir = parseInt(dir, 10);
    }

    setStageQuery({
      ...stageQuery,
      stepSortCol: col,
      stepSortDir: dir
    });
  }

  const sortOptions = [
    {
      label: 'Clear Sort',
      command: () => {
        sortStep(null, null);
      }
    },
    {
      label: 'Step Ascending',
      command: () => {
        sortStep(stepSortCol.STEP_ID, sortOrder.ASCENDING);
      }
    },
    {
      label: 'Step Descending',
      command: () => {
        sortStep(stepSortCol.STEP_ID, sortOrder.DESCENDING);
      }
    }
  ];

  // Add extra sorting options to Holding stage list
  if (stageQuery.stage === StageValues.Holding) {
    sortOptions.push({
      label: 'Appointment Date Ascending',
      command: () => {
        sortStep(stepSortCol.APPOINTMENT_DATE, sortOrder.ASCENDING);
      }
    });

    sortOptions.push({
      label: 'Appointment Date Descending',
      command: () => {
        sortStep(stepSortCol.APPOINTMENT_DATE, sortOrder.DESCENDING);
      }
    });
  }

  const filterClear = {
    label: 'Clear Filter',
    command: () => {
      filterStep(null);
    }
  };

  const filterOptions = [filterClear, ...getStepValueFilterOptions(filterStep)];

  const displayLoading = () => <LoadingSpinner />;

  const displayStageSteps = () => (
    <React.Fragment>
      <div className="row">
        <div className="col d-flex justify-content-end">
          <Menu model={filterOptions} popup ref={filterMenu} id="filter_menu" />
          <button type="button" className="button-secondary" onClick={(event) => filterMenu.current.toggle(event)}>
            <img src={Filter} alt="Filter" />
            &nbsp;
            Filter
          </button>
          <div className="ms-3">
            <Menu model={sortOptions} popup ref={sortMenu} id="sort_menu" />
            <button type="button" className="button-secondary" onClick={(event) => sortMenu.current.toggle(event)}>
              <img src={Sort} alt="Sort" />
              &nbsp;
              Sort
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          {stageSteps.map(step => <StageStepCard key={step.id} activeStep={step} handleReload={reloadPage} />)}
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <PageHeader title="Patient Stages"
        btnOptions={addOptions}
        stageQuery={stageQuery}
        setAllStages={setAllStages}
        setStageValue={setStageValue} />
      <div className="container-fluid container__wide container-main stages-wrapper">

        {loading
          ? displayLoading()
          : displayStageSteps()
        }

        {hasMore &&
          <LoadMore handleSearch={loadMore} />
        }

      </div>

      <Dialog header="Add Patient" footer={<div></div>} visible={display} style={{ width: '50vw' }} modal onHide={() => closeEdit()}>
        <PatientDialog closeEdit={closeEdit} />
      </Dialog>

    </React.Fragment>
  );
}

export default withRouter(PatientStages);



import React, { useState } from 'react';
import { classNames } from 'primereact/utils';

import PatientInfo from './PatientInfo';
import PatientInsurance from './PatientInsurance';
import PatientFirstStep from './PatientFirstStep';

import NewPatientDto from '../../DTOs/patient/NewPatientDto';
import EditPatientDto from '../../DTOs/patient/EditPatientDto';

const getPatientInfo = (currentPatient) => {
  return currentPatient ? new EditPatientDto(currentPatient) : new NewPatientDto();
}

const PatientDialog = (props) => {
  const [patientInfo, setPatientInfo] = useState(getPatientInfo(props.currentPatient));
  const [showPatientInfo, setShowPatientInfo] = useState(true);
  const [showInsuranceInfo, setShowInsuranceInfo] = useState(false);
  const [showCreateStep, setShowCreateStep] = useState(false);

  const handleInfoNext = (patientInfo) => {
    setPatientInfo(patientInfo);
    setShowPatientInfo(false);
    setShowInsuranceInfo(true);
    setShowCreateStep(false);
  }

  const handleInsuranceNext = (patientInfo) => {
    setPatientInfo(patientInfo);
    setShowPatientInfo(false);
    setShowInsuranceInfo(false);
    setShowCreateStep(true);
  }

  const handleInsuranceBack = (patientInfo) => {
    setPatientInfo(patientInfo);
    setShowPatientInfo(true);
    setShowInsuranceInfo(false);
    setShowCreateStep(false);
  }

  const handleFirstStepBack = (patientInfo) => {
    setPatientInfo(patientInfo);
    setShowPatientInfo(false);
    setShowInsuranceInfo(true);
    setShowCreateStep(false);
  }


  const getDialogBody = () => {
    if (showPatientInfo) {
      return <PatientInfo patientInfo={patientInfo} closeEdit={props.closeEdit}
        handleNext={handleInfoNext} />;
    } else if (showInsuranceInfo) {
      return <PatientInsurance patientInfo={patientInfo} closeEdit={props.closeEdit}
        handleNext={handleInsuranceNext} handleBack={handleInsuranceBack} />;
    } else {
      return <PatientFirstStep patientInfo={patientInfo} closeEdit={props.closeEdit}
      handleBack={handleFirstStepBack} />;
    }
  };

  return (
    <React.Fragment>
      <div className="row patient-step__wrapper">
        <div className="col d-flex">
          <div className={classNames('d-flex justify-content-center align-items-center patient-step',
            { 'patient-step__active': showPatientInfo })}>
            1
          </div>
          <div className={classNames('ms-3 patient-step__text', { 'patient-step__text-active': showPatientInfo })}>
            Patient Details
          </div>
          <div className={classNames('d-flex justify-content-center align-items-center patient-step ms-5',
            { 'patient-step__active': showInsuranceInfo })}>
            2
          </div>
          <div className={classNames('ms-3 patient-step__text', { 'patient-step__text-active': showInsuranceInfo })}>
            Insurer Information
          </div>
          {!props.currentPatient &&
            <React.Fragment>
              <div className={classNames('d-flex justify-content-center align-items-center patient-step ms-5',
                { 'patient-step__active': showCreateStep })}>
                3
              </div>
              <div className={classNames('ms-3 patient-step__text', { 'patient-step__text-active': showCreateStep })}>
                Create New Step
              </div>

            </React.Fragment>
          }
        </div>
      </div>
      {getDialogBody()}
    </React.Fragment>
  );
}

export default PatientDialog;

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';

import RequestPriorAuth from './completeSteps/RequestPriorAuth';
import PriorAuthPending from './completeSteps/PriorAuthPending';
import Botox from './completeSteps/Botox';
import AppointmentScheduled from './addSteps/AppointmentScheduled';
import SetAsInactive from './addSteps/SetAsInactive';

import SaveStageStepDto from '../../DTOs/stageStep/SaveStageStepDto';

import { StageValues, StageValueNames, StepValueNames, StageStepStatusValues } from '../../constants/StageStepConstants';
import api from '../../scripts/api';
import { message } from '../shared/Message';
import { variantType } from '../../constants/MessageConstants';

import PlusSquare from '../../images/plus-square.png';

const CompleteStep = (props) => {
  const [showComments, setShowComments] = useState(false);

  const step = {
    ...props.stepInfo,
    addNewStep: props.markComplete
  };
  const defaultValues = step;

  const { control, watch, formState: { errors }, handleSubmit, getValues, setValue } = useForm({ shouldFocusError: false, defaultValues });
  const statusValue = watch('statusId');  // watching the statusId to trigger re-rendering of the body component with/without validation

  const onSubmit = async (data) => {
    console.log(data);
    const formData = {
      ...props.stepInfo,
      ...data,
      addNewStep: data.statusId === StageStepStatusValues.Completed // determines whether or not to save or progress to Add New Step
    };

    if (formData.addNewStep) {
      props.handleNext(formData);
    } else {
      let reloadPage = false;

      try {
        console.log(formData);
        const dto = new SaveStageStepDto({ currentStepDto: formData });
        await api.saveStageStep(dto);
        reloadPage = true;
      } catch (e) {
        console.log(e.payload);
        const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
        message.createMessageObject(msg, variantType.error);
      }

      props.closeEdit(reloadPage);
    }
  }

  const toggleComments = () => {
    setShowComments(!showComments);
  }

  const getBodyComponent = () => {
    if (!step)
      return null;

    if (step.stageId === StageValues.RequestPriorAuth) {
      return <RequestPriorAuth errors={errors} control={control}
        doValidations={statusValue === StageStepStatusValues.Completed} />;
    } else if (step.stageId === StageValues.PriorAuthPending) {
      return <PriorAuthPending errors={errors} control={control} setValue={setValue} decisionType={props.stepInfo.priorAuthDecisionId}
        doValidations={statusValue === StageStepStatusValues.Completed} />;
    } else if (step.stageId === StageValues.BotoxProcurement) {
      return <Botox errors={errors} control={control} getValues={getValues}
        doValidations={statusValue === StageStepStatusValues.Completed} />;
    } else if (step.stageId === StageValues.Holding) {
      return <AppointmentScheduled errors={errors} control={control} />;
    } else if (step.stageId === StageValues.Inactive) {
      return <SetAsInactive errors={errors} control={control} watch={watch} />;
    }

    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
      <div className="row mb-3">
        <div className="col-6">
          <div className="field-label">Stage</div>
          {StageValueNames[step.stageId]}
        </div>
        <div className="col-6">
          <div className="field-label">Step</div>
          {StepValueNames[step.stepId]}
        </div>
      </div>

      <div className="row mb-3">
        <Controller name="statusId" control={control}
          render={({ field: { onChange, value } }) => (
            <React.Fragment>
              <div className="field-label">
                Step Status
              </div>
              <div className="col d-flex">
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="statusIdInProcess" name="status" value={StageStepStatusValues.InProgress} onChange={onChange} checked={value === StageStepStatusValues.InProgress} />
                  <label htmlFor="statusIdInProcess" className="ms-3 radio-label">In Progress</label>
                </div>
                <div className="mt-2 mx-5 d-flex align-items-center">
                  <RadioButton inputId="statusIdComplete" name="status" value={StageStepStatusValues.Completed} onChange={onChange} checked={value === StageStepStatusValues.Completed} />
                  <label htmlFor="addNewStepNo" className="ms-3 radio-label">Complete</label>
                </div>
              </div>
            </React.Fragment>
          )} />
      </div>

      {getBodyComponent()}

      <div className="row mb-3">
        <div className="col-6">
          {props.markComplete &&
            <React.Fragment>
              <Controller name="addNewStep" control={control}
                render={({ field: { onChange, value } }) => (
                  <React.Fragment>
                    <div className="field-label">
                      Add New Step
                    </div>
                    <div className="mt-2 d-flex align-items-center">
                      <RadioButton inputId="addNewStepYes" name="newStep" value={true} onChange={onChange} checked={value === true} />
                      <label htmlFor="addNewStepYes" className="ms-3 radio-label">Yes</label>
                    </div>
                    <div className="mt-2 d-flex align-items-center">
                      <RadioButton inputId="addNewStepNo" name="newStep" value={false} onChange={onChange} checked={value === false} />
                      <label htmlFor="addNewStepNo" className="ms-3 radio-label">No</label>
                    </div>
                  </React.Fragment>
                )} />
            </React.Fragment>
          }
        </div>
        <div className="offset-6 col-6 d-flex justify-content-end" onClick={() => toggleComments()}>
          <div className="add-comment me-4">
            <img src={PlusSquare} alt="Add comments" />
          </div>
          <div className="add-comment__text">
            Add/Edit Comments
          </div>
        </div>
      </div>

      {
        showComments &&
        <div className="row">
          <div className="col">
            <label htmlFor="stepComment" className="field-label">Comment</label>
            <Controller name="stepComment" control={control} render={({ field }) => (
              <InputTextarea id={field.stepComments} placeholder="Step Comment" {...field} />
            )} />
          </div>
        </div>
      }

      <div className="row mt-4">
        <div className="col-6 d-flex justify-content-start">
          <button type="button" className="button-cancel" onClick={() => props.closeEdit()}>Cancel</button>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <button className="button-primary" type="submit">{statusValue === StageStepStatusValues.InProgress ? 'Save' : 'Next'}</button>
        </div>
      </div>

    </form>
  );
}

export default CompleteStep;

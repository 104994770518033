import EditStageStepDto from './EditStageStepDto';
import AddNewStageStepDto from './AddNewStageStepDto';

export default class SaveStageStepDto {
  constructor(options = {}) {
    this.currentStepDto = new EditStageStepDto(options.hasOwnProperty('currentStepDto') ? options.currentStepDto : {});
    this.addNewStepDto = options.hasOwnProperty('addNewStepDto') ? new AddNewStageStepDto(options.addNewStepDto) : null;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

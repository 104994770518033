import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';

import EditPatientDto from '../../DTOs/patient/EditPatientDto';

import { getFormErrorMessage } from '../../scripts/helperFunctions';
import { message } from '../shared/Message';
import api from '../../scripts/api';
import { variantType } from '../../constants/MessageConstants';

import PlusSquare from '../../images/plus-square.png';

const PatientInsurance = (props) => {
  const [showComments, setShowComments] = useState(false);
  const [healthInsurance, setHealthInsurance] = useState([]);
  const [rxInsurance, setRxInsurance] = useState([]);
  const [pharmacies, setPharmacies] = useState([]);
  const [pharmacyPhone, setPharmacyPhone] = useState(null);

  const defaultValues = props.patientInfo;

  const { control, formState: { errors }, handleSubmit, getValues, setValue } = useForm({ shouldFocusError: false, defaultValues });

  useEffect(() => {
    const getInsuranceOptions = async () => {
      try {
        const result = await api.getInsuranceInfo();

        setHealthInsurance(result.activeHealthInsurance);
        setRxInsurance(result.activeRxInsurance);
        setPharmacies(result.activePharmacies);
      } catch (e) {
        console.log(e.payload);
        const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
        message.createMessageObject(msg, variantType.error);
      }
    }

    getInsuranceOptions();
  }, []);

  const onSubmit = async (data) => {
    console.log(data);

    if (props.patientInfo.id > 0) {
      try {
        await api.editPatient(new EditPatientDto({ ...props.patientInfo, ...data }));
        props.closeEdit(true);
      } catch (e) {
        console.log(e.payload);
        const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
        message.createMessageObject(msg, variantType.error);
      }
    } else {
      props.handleNext({ ...props.patientInfo, ...data });
    }
  }

  const toggleComments = () => {
    setShowComments(!showComments);
  }

  const selectPharmacy = (pharmacyId) => {
    setValue('specialtyPharmacyId', pharmacyId, { shouldDirty: true });
    const phone = pharmacies.length > 0 && pharmacyId > 0 ? pharmacies.filter(x => x.pharmacyId === pharmacyId)[0].phone : '';
    setPharmacyPhone(phone);
  }

  const healthOptions = healthInsurance.map(x => ({ label: x.insuranceName, value: x.insuranceId }));
  const rxOptions = rxInsurance.map(x => ({ label: x.insuranceName, value: x.insuranceId }));
  const pharmacyOptions = pharmacies.map(x => ({ label: x.pharmacyName, value: x.pharmacyId }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
      <div className="row  mb-3">
        <div className="col-6">
          <label htmlFor="healthInsuranceId" className={classNames('field-label', { 'p-error': errors.healthInsuranceId })}>Health Insurance</label>
          <Controller name="healthInsuranceId" control={control}
            rules={{ min: { value: 1, message: 'Health Insurance is required' } }}
            render={({ field, fieldState }) => (
              <Dropdown id={field.healthInsuranceId} options={healthOptions} placeholder="-- select --" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          {getFormErrorMessage(errors, 'healthInsuranceId')}
        </div>
        <div className="col-6">
          <label htmlFor="healthInsurancePhone" className={classNames('field-label', { 'p-error': errors.healthInsurancePhone })}>Phone</label>
          <Controller name="healthInsurancePhone" control={control}
            rules={{
              required: 'Phone is required',
              pattern: { value: /^\(\d{3}\) \d{3}-\d{4}$/i, message: 'Invalid format e.g. (999) 999-9999' }
            }}
            render={({ field, fieldState }) => (
              <InputMask id={field.healthInsurancePhone} mask="(999) 999-9999" placeholder="(999) 999-9999" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          {getFormErrorMessage(errors, 'healthInsurancePhone')}
        </div>
      </div>

      <div className="row  mb-3">
        <div className="col-6">
          <label htmlFor="rxInsuranceId" className={classNames('field-label', { 'p-error': errors.rxInsuranceId })}>Rx Insurance</label>
          <Controller name="rxInsuranceId" control={control}
            rules={{ min: { value: 1, message: 'Rx Insurance is required' } }}
            render={({ field, fieldState }) => (
              <Dropdown id={field.rxInsuranceId} options={rxOptions} placeholder="-- select --" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          {getFormErrorMessage(errors, 'rxInsuranceId')}
        </div>
        <div className="col-6">
          <label htmlFor="rxInsurancePhone" className={classNames('field-label', { 'p-error': errors.rxInsurancePhone })}>Phone</label>
          <Controller name="rxInsurancePhone" control={control}
            rules={{
              required: 'Phone is required',
              pattern: { value: /^\(\d{3}\) \d{3}-\d{4}$/i, message: 'Invalid format e.g. (999) 999-9999' }
            }}
            render={({ field, fieldState }) => (
              <InputMask id={field.rxInsurancePhone} mask="(999) 999-9999" mask="(999) 999-9999" placeholder="(999) 999-9999" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          {getFormErrorMessage(errors, 'rxInsurancePhone')}
        </div>
      </div>

      <div className="row  mb-3">
        <div className="col-6">
          <label htmlFor="specialtyPharmacyId" className={classNames('field-label', { 'p-error': errors.specialtyPharmacyId })}>Specialty Pharmacy</label>
          <Controller name="specialtyPharmacyId" control={control}
            rules={{ min: { value: 1, message: 'Pharmacy is required' } }}
            render={({ field, fieldState }) => (
              <Dropdown id={field.specialtyPharmacyId}
                options={pharmacyOptions}
                placeholder="-- select --"
                {...field}
                className={classNames({ 'p-invalid': fieldState.invalid })}
                onChange={(e) => selectPharmacy(e.value)} />
            )} />
          {getFormErrorMessage(errors, 'specialtyPharmacyId')}
        </div>
        <div className="col-6">
          <div className="field-label">Phone</div>
          <div className="specialty-phone">
            {pharmacyPhone}
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col d-flex justify-content-end" onClick={() => toggleComments()}>
          <div className="add-comment me-4">
            <img src={PlusSquare} alt="Add comments" />
          </div>
          <div className="add-comment__text">
            Add/Edit Comments
          </div>
        </div>
      </div>

      {showComments &&
        <div className="row">
          <div className="col">
            <label htmlFor="insuranceComments" className="field-label">Comments</label>
            <Controller name="insuranceComments" control={control} render={({ field }) => (
              <InputTextarea id={field.insuranceComments} placeholder="Insurance Comments" {...field} />
            )} />
          </div>
        </div>
      }

      <div className="row mt-4">
        <div className="col-6 d-flex justify-content-start">
          <button type="button" className="button-cancel"
            onClick={() => props.handleBack({ ...props.patientInfo, ...getValues() })}>Back</button>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <button className="button-primary" type="submit">{props.patientInfo.id === 0 ? 'Next' : 'Save Patient'}</button>
        </div>
      </div>

    </form>
  );
}

export default PatientInsurance;

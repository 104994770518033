import React from 'react';
import { Controller } from 'react-hook-form';
import { InputMask } from 'primereact/inputmask';
import { classNames } from 'primereact/utils';

import { getFormErrorMessage } from '../../../scripts/helperFunctions';

const AppointmentScheduled = ({ errors, control }) => {

  return (
    <div className="row mb-3">
      <div className="col-6">
        <label htmlFor="appointmentDateUtc" className={classNames('field-label', { 'p-error': errors.appointmentDateUtc })}>Appointment Date</label>
        <Controller name="appointmentDateUtc" control={control}
          rules={{
            required: 'Appointment Date is required',
            pattern: { value: /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/i, message: 'Invalid date format e.g. mm/dd/yyyy' }
          }}
          render={({ field, fieldState }) => (
            <InputMask id={field.appointmentDateUtc} mask="99/99/9999" placeholder="mm/dd/yyyy" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
          )} />
        {getFormErrorMessage(errors, 'appointmentDateUtc')}
      </div>
    </div>
  );
}

export default AppointmentScheduled;

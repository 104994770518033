import React, { Component } from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AutoComplete } from 'primereact/autocomplete';

import { Auth0Context } from './Auth0';
import { navigationUrls, userRoles } from '../../constants';

import { message } from '../shared/Message';
import api from '../../scripts/api';
import { variantType } from '../../constants/MessageConstants';
import SearchPatientDto from '../../DTOs/patient/SearchPatientDto';

import '../../css/navMenu.css';

const getLinks = () => [
  {
    text: navigationUrls.dashboard.DisplayText,
    url: navigationUrls.dashboard.URL
  },
  {
    text: navigationUrls.patientStages.DisplayText,
    url: navigationUrls.patientStages.URL
  },
  //{
  //  text: navigationUrls.patient.DisplayText,
  //  url: `${navigationUrls.patient.URL}/3`
  //}
];

const getDropdownItems = () => [
  {
    text: navigationUrls.users.DisplayText,
    url: navigationUrls.users.URL,
    className: 'users',
    authorization: [
      userRoles.SiteAdmin,
      userRoles.OrgAdmin
    ],
    action: component => component.props.history.push(navigationUrls.users.URL)
  }, {
    text: navigationUrls.offices.DisplayText,
    url: navigationUrls.offices.URL,
    className: 'offices',
    authorization: [
      userRoles.SiteAdmin,
      userRoles.OrgAdmin
    ],
    action: component => component.props.history.push(navigationUrls.offices.URL)
  },
  {
    text: navigationUrls.healthInsurance.DisplayText,
    url: navigationUrls.healthInsurance.URL,
    className: 'health-insurance',
    authorization: [
      userRoles.SiteAdmin,
      userRoles.OrgAdmin
    ],
    action: component => component.props.history.push(navigationUrls.healthInsurance.URL)
  },
  {
    text: navigationUrls.rxInsurance.DisplayText,
    url: navigationUrls.rxInsurance.URL,
    className: 'rx-insurance',
    authorization: [
      userRoles.SiteAdmin,
      userRoles.OrgAdmin
    ],
    action: component => component.props.history.push(navigationUrls.rxInsurance.URL)
  },
  {
    text: navigationUrls.specialtyPharmacy.DisplayText,
    url: navigationUrls.specialtyPharmacy.URL,
    className: 'specialty-insurance',
    authorization: [
      userRoles.SiteAdmin,
      userRoles.OrgAdmin
    ],
    action: component => component.props.history.push(navigationUrls.specialtyPharmacy.URL)
  },
  {
    text: 'Log Out',
    url: '/',
    className: 'logout',
    alwaysAllow: true,
    action: component => component.context.logout({ returnTo: window.location.origin })
  }
];

export class NavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPatient: null,
      filteredPatients: []
    };
  }

  static contextType = Auth0Context;

  setPatient = (event) => {
    if (typeof event.value === 'string') {
      this.setState({ selectedPatient: event.value });
    } else {
      console.log(event.value);
      this.setState({ selectedPatient: '' });
      this.props.history.push(`/patient/${event.value.patientId}`);
    }
  }

  searchPatient = async (event) => {
    if (!event.query.trim().length) {
      this.setState({ filteredPatients: [] });
    }
    else {
      try {
        const result = await api.searchPatient(new SearchPatientDto({ searchString: event.query.toLowerCase()}));
        const filteredPatients = result.patientresult;
        this.setState({ filteredPatients });
      } catch (e) {
        const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
        message.createMessageObject(msg, variantType.error);
      }
    }
  }

  displayNav = () => {
    const { roles, fullName } = this.props;
    const self = this;

    const links = getLinks();
    const dropdownItems = getDropdownItems();

    const initials = fullName.split(' ').map(i => i.charAt(0)).join('').toUpperCase();

    return (
      <header>
        <nav className="header__main-nav navbar navbar-expand-md navbar-light">
          <div className="container-fluid container__wide">
            <a className="navbar-brand" href="#">
              <h1 className="header-text">
                Hound PA
              </h1>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarToggler">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div id="navbarToggler" className="collapse navbar-collapse justify-content-end">
              <ul className="navbar-nav align-items-end">
                {links.map(link => {
                  return (
                    <li key={link.text} className="nav-item d-flex align-items-center">
                      <NavLink
                        exact
                        to={link.url}
                        key={link.url}
                        className="nav-link"
                        aria-label={link.DisplayText}>
                        {link.text}
                      </NavLink>
                    </li>
                  );
                })}
                <div className="d-flex align-items-center nav-search-item">
                  <span className="nav-search-icon pi pi-search" />
                  <AutoComplete
                    value={this.state.selectedPatient}
                    suggestions={this.state.filteredPatients}
                    completeMethod={this.searchPatient}
                    field="displayName"
                    size="50"
                    placeholder="Search Patient..."
                    onChange={this.setPatient} />
                </div>
                <li className="nav-item dropdown d-flex align-items-center">
                  <Link
                    to="#"
                    className="nav-link nav-initials dropdown-toggle"
                    id="navbarDropdown"
                    aria-label="Account Dropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    {initials}
                  </Link>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="dropdown-item d-flex align-items-center">
                      <i className="pi pi-user" />
                      <div className="dropdown-user">
                        {fullName}
                        {roles.some(role => !roles.includes(userRoles.OrgUser)) &&
                          <div className="dropdown-user-role">Admin</div>
                        }
                      </div>
                    </div>
                    {
                      dropdownItems.map((item) => {
                        const authorizations = item.authorization;
                        if (item.alwaysAllow || roles.some(role => authorizations.includes(role))) {
                          return (
                            <a
                              key={item.text}
                              className={`dropdown-item ${item.className} ${window.location.pathname === item.URL ? 'current' : ''}`}
                              onClick={item.action.bind(null, self)}>
                              {item.text}
                            </a>
                          );
                        }
                        return null;
                      })
                    }
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header >
    );
  }

  render() {
    return (
      <React.Fragment>
        {
          this.displayNav()
        }
      </React.Fragment>
    );
  }
}

export default withRouter(connect(
  state => ({ roles: state.user.roles, fullName: state.user.fullName }),
)(NavMenu));

import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';

import UserDisplay from './UserDisplay';
import UserDialog from './UserDialog';
import PageHeader from '../shared/PageHeader';

import api from '../../scripts/api';

import '../../css/users.css';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [display, setDisplay] = useState(false);

  const getAllUsers = async () => {
    const [hasResult, result] = await api.getAllUsers(); // returns [] due to the is404Success flag in the api call

    if (hasResult) {
      setUsers(result.allusers);
    } else {
      setUsers([]);
    }
  }

  useEffect(() => {
    getAllUsers();
  }, []);

  const createNew = () => {
    setDisplay(true);
  }

  const editUser = (user) => {
    setCurrentUser(user);
    setDisplay(true);
  }

  const closeEdit = (reload = false) => {
    setCurrentUser(null);
    setDisplay(false);

    if (reload) {
      getAllUsers();
    }
  }

  const addOptions = {
    text: 'Add New User',
    icon: 'pi pi-plus',
    command: createNew
  }

  return (
    <React.Fragment>
      <PageHeader title="Users" btnOptions={addOptions} />
      <div className="container-fluid container__wide container-main users-wrapper">

        <div className="row">
          <div className="col">
            {users.map(user => <UserDisplay key={user.userId} user={user} handleEdit={editUser} />)}
          </div>
        </div>
      </div>

      <Dialog header={currentUser ? 'Edit User' : 'Add New User'} footer={<div></div>} visible={display} style={{ width: '50vw' }} modal onHide={() => closeEdit()}>
        <UserDialog closeEdit={closeEdit} currentUser={currentUser} />
      </Dialog>
    </React.Fragment>
  );
}

export default Users;



import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';

import PageHeader from '../shared/PageHeader';
import LoadingSpinner from '../shared/LoadingSpinner';
import PatientDialog from './PatientDialog';
import PatientDetails from './PatientDetails';
import StageStepCard from '../stages/StageStepCard';
import NewStepDialog from './NewStepDialog';
import { message } from '../shared/Message';

import ArrowDown from '../../images/arrow-down.png';

import api from '../../scripts/api';
import { variantType } from '../../constants/MessageConstants';

import '../../css/patient.css';
import '../../css/stages.css';

export const Patient = (props) => {
  const [currentPatient, setCurrentPatient] = useState(null);
  const [activeSteps, setActiveSteps] = useState([]);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [priorAuthorization, setPriorAuthorization] = useState(null);
  const [showActiveSteps, setShowActiveSteps] = useState(true);
  const [showCompletedSteps, setShowCompletedSteps] = useState(true);
  const [display, setDisplay] = useState(false);
  const [displayNewStep, setDisplayNewStep] = useState(false);

  const getPatient = async () => {
    const id = props.match.params['id'];

    try {
      const result = await api.getPatient(id);
      setCurrentPatient(result.patientDetails);
      setActiveSteps(result.activeSteps);
      setCompletedSteps(result.completedSteps);
      setPriorAuthorization(result.priorAuthorization);
    } catch (e) {
      const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
      message.createMessageObject(msg, variantType.error);
    }
  }

  useEffect(() => {
    getPatient();
  }, [props.match.params['id']]);

  const editPatient = () => {
    setDisplay(true);
  }

  const closeEdit = (reload = false) => {
    setDisplay(false);
    setDisplayNewStep(false);

    if (reload) {
      getPatient();
    }
  }

  const getTitle = () => {
    return (<div className="d-flex">
      <div className="me-2">Patient Stages</div>
      {currentPatient &&
        <React.Fragment>
          <i className='pi pi-angle-right me-2' style={{ 'fontSize': '1.5rem' }} />
          <div>{`${currentPatient.firstName} ${currentPatient.lastName}`}</div>
        </React.Fragment>
      }
    </div>
    );
  }

  const toggleActiveSteps = () => {
    setShowActiveSteps(!showActiveSteps);
  }

  const toggleCompletedSteps = () => {
    setShowCompletedSteps(!showCompletedSteps);
  }

  const displayLoading = () => <LoadingSpinner />;

  const displayPatient = () => {
    return (
      <React.Fragment>
        <div className="container-fluid container__wide container-main insurance-wrapper">
          <div className="row">
            <div className="col-3">
              <PatientDetails currentPatient={currentPatient} editPatient={editPatient} />
            </div>
            <div className="col-9">

              {priorAuthorization &&
                <div className="mb-3 prior-auth-card">
                  <div className="row">
                    <div className="col-3">
                      <div className="prior-auth-card__label">PA Status</div>
                      <div className="prior-auth-card__data">{priorAuthorization.decisionText}</div>
                    </div>
                    <div className="col-3">
                      <div className="prior-auth-card__label">PA Number</div>
                      <div className="prior-auth-card__data">{priorAuthorization.paNumber}</div>
                    </div>
                    <div className="col-3">
                      <div className="prior-auth-card__label">PA Approval Date</div>
                      <div className="prior-auth-card__data">{priorAuthorization.paStartDateUtc}</div>
                    </div>
                    <div className="col-3">
                      <div className="prior-auth-card__label">PA Expiration Date</div>
                      <div className="prior-auth-card__data">{priorAuthorization.paEndDateUtc}</div>
                    </div>
                  </div>
                </div>
              }

              <div className="stage-section__title d-flex justify-content-between align-items-center" onClick={toggleActiveSteps}>
                <div className="stage-section__label">
                  Steps In Progress
                </div>
                <img className={classNames('stage-section__arrow-down', { 'state-section__rotate': !showActiveSteps })} alt="Active Steps" src={ArrowDown} />
              </div>
              {showActiveSteps &&
                activeSteps.map(step => <StageStepCard key={step.id} activeStep={step} handleReload={getPatient} />)
              }

              <div className="stage-section__title d-flex justify-content-between align-items-center mt-3" onClick={toggleCompletedSteps}>
                <div className="stage-section__label">
                  Steps Completed
                </div>
                <img className={classNames('stage-section__arrow-down', { 'state-section__rotate': !showCompletedSteps })} alt="Completed Steps" src={ArrowDown} />
              </div>
              {showCompletedSteps &&
                completedSteps.map(step => <StageStepCard key={step.id} activeStep={step} />)
              }

            </div>
          </div>
        </div>

        <Dialog header="Edit Patient" footer={<div></div>} visible={display} style={{ width: '50vw' }} modal onHide={() => closeEdit()}>
          <PatientDialog closeEdit={closeEdit} currentPatient={currentPatient} updatePatient={setCurrentPatient} />
        </Dialog>

        <Dialog header="Add Step" footer={<div></div>} visible={displayNewStep} style={{ width: '50vw' }} modal onHide={() => closeEdit()}>
          <NewStepDialog closeEdit={closeEdit} patientId={currentPatient.id} />
        </Dialog>

      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <PageHeader title={getTitle()} />
      {currentPatient ? displayPatient() : displayLoading()}
    </React.Fragment>
  );

}

export default withRouter(Patient);

import React from 'react';

export default () => {
  const curDate = new Date();

  return (
    <footer
      className="footer text-muted d-flex align-items-center justify-content-center d-print-none"
    >
      © {curDate.getFullYear()} - {process.env.REACT_APP_AUTHORS} {process.env.REACT_APP_VERSION}
    </footer>
  );
};

import { apiCall } from './api.base.js';

import OfficeListDto from '../DTOs/office/OfficeListDto';

export default {
  /**
   * Requests system office' info
   * @param {Object} dto
   * @returns {Promise<[hasResult: boolean, result: OfficeListDto]>} - @see OfficeListDto
   */
  getAllOffices() {
    return apiCall('office', {
      responseType: OfficeListDto,
      is404Success: true
    });
  },

  /**
   * Requests active office' info
   * @param {Object} dto
   * @returns {Promise<[hasResult: boolean, result: OfficeListDto]>} - @see OfficeListDto
   */
  getActiveOffices() {
    return apiCall('office/active', {
      responseType: OfficeListDto,
      is404Success: true
    });
  },

  /**
   * Create Office
   * @param {any} dto
   */
  createOffice(dto) {
    return apiCall('office', { method: 'POST', body: dto });
  },

  /**
   * Edit Office
   * @param {any} dto
   */
  editOffice(id, dto) {
    return apiCall(`office/${id}`, { method: 'PUT', body: dto });
  }
}

import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import auth0Base from '../../scripts/auth0Base';

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

const defaultRedirectCallback = (appState, history) => {
  const url = appState && appState.targetUrl ? appState.targetUrl : window.location.pathname;
  history.replace(url, {});
};

export const Auth0Provider = withRouter(({ children, onRedirectCallback = defaultRedirectCallback, history, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [loading, setLoading] = useState(true);
  const [auth0Client, setAuth0] = useState();

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0Client = await auth0Base.getAuth0Client();
      setAuth0(auth0Client);

      // 'code' is present in search when the user logs in on Auth0 and is redirected back
      if (window.location.search.includes("code=")) {
        const { appState } = await auth0Client.handleRedirectCallback();
        onRedirectCallback(appState, history);
      }

      const isAuthenticated = await auth0Client.isAuthenticated();
      setIsAuthenticated(isAuthenticated);
      setLoading(false);
    };

    initAuth0();
  }, [history, onRedirectCallback]);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        loading,
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
});

import React from 'react';

const LoadMore = (props) => {
  return (
    <div className="mt-3 row">
      <div className="col">
        <button type="button" className="button-secondary button-load-more" onClick={props.handleSearch}>
          Load More
        </button>
      </div>
    </div>
  );
}

export default LoadMore;

import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

export const LoadingSpinnerId = "LoadingSpinner";

const LoadingSpinner = (props) => {
  const { hideText } = props;
  return (
    <div id={LoadingSpinnerId} className="row pt-5 loading-container">
      <div className="col">
        <div className={`${hideText ? 'd-none' : 'd-flex justify-content-center text--dark text-font--bold font-size-22'}`}>Loading Content</div>
        <div className={`${hideText ? 'd-none' : 'd-flex justify-content-center pt-4 text-color-active'}`}>Please wait while we load your page.</div>
        <div className="d-flex justify-content-center pt-4">
          <ProgressSpinner />
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;

export const StageValues = {
  RequestPriorAuth: 10,
  PriorAuthPending: 20,
  BotoxProcurement: 30,
  ScheduleAppointments: 40,
  Holding: 50,
  Inactive: 60
}

export const StageValueNames = {
  10: 'Request Prior Auth',
  20: 'Prior Auth Pending',
  30: 'Botox Procurement',
  40: 'Schedule Appointments',
  50: 'Holding',
  60: 'Inactive'
}

export const StepValues = {
  SubmitRxPriorAuth: 10,
  SubmitHealthPriorAuth: 20,
  AwaitingResponse: 30,
  ContactRxInsurance: 40,
  ContactHealthInsurance: 50,
  SubmitAdditionalDocuments: 60,
  SubmitAppeal: 70,
  ContactPatient: 80,
  ContactSpecialtyPharmacy: 90,
  ScheduleAppointment: 100,
  //RenewPriorAuth: 110, // no longer used
  AppointmentScheduled: 120,
  SetAsInactive: 130
}

export const StepValueNames = {
  10: 'Submit Rx Prior Auth',
  20: 'Submit Health Prior Auth',
  30: 'Awaiting Response',
  40: 'Contact Rx Insurance',
  50: 'Contact Health Insurance',
  60: 'Submit Additional Documents',
  70: 'Submit Appeal',
  80: 'Contact Patient',
  90: 'Contact Specialty Pharmacy',
  100: 'Schedule Appointment',
  //110: 'Renew Prior Auth',  // no longer used
  120: 'Appointment Scheduled',
  130: 'Set As Inactive'
}

export const OffBoardOptions = {
  Moved: 'Moved',
  Insurance: 'Lack of Insurance Coverage',
  Other: 'Other'
}

export const StageStepStatusValues = {
  InProgress: 1,
  Completed: 2
}

export const InsurancePathValues = {
  'Health': 1,
  'Prescription': 2,
  'BuyAndBill': 3
}

export const DecisionTypes = {
  Approved: 1,
  Declined: 2,
  Appealed: 3
}

export const DecisionTypeNames = {
  1: 'Approved',
  2: 'Denied',
  3: 'Appealed'
}

export const StageStepMapping = {
  10: [	// StageValues.RequestPriorAuth
    {
      label: StepValueNames[StepValues.SubmitRxPriorAuth],
      value: StepValues.SubmitRxPriorAuth
    },
    {
      label: StepValueNames[StepValues.SubmitHealthPriorAuth],
      value: StepValues.SubmitHealthPriorAuth
    }
  ],
  20: [	// StageValues.PriorAuthPending
    {
      label: StepValueNames[StepValues.ContactHealthInsurance],
      value: StepValues.ContactRxInsurance
    },
    {
      label: StepValueNames[StepValues.ContactRxInsurance],
      value: StepValues.ContactRxInsurance
    },
    {
      label: StepValueNames[StepValues.SubmitAppeal],
      value: StepValues.SubmitAppeal
    },
    {
      label: StepValueNames[StepValues.SubmitAdditionalDocuments],
      value: StepValues.SubmitAdditionalDocuments
    },
    {
      label: StepValueNames[StepValues.AwaitingResponse],
      value: StepValues.AwaitingResponse
    },
    {
      label: StepValueNames[StepValues.ContactPatient],
      value: StepValues.ContactPatient
    }
  ],
  30: [	// StageValues.BotoxProcurement
    {
      label: StepValueNames[StepValues.ContactPatient],
      value: StepValues.ContactPatient
    },
    {
      label: StepValueNames[StepValues.ContactSpecialtyPharmacy],
      value: StepValues.ContactSpecialtyPharmacy
    }
  ],
  40: [	// StageValues.ScheduleAppointments
    {
      label: StepValueNames[StepValues.ScheduleAppointment],
      value: StepValues.ScheduleAppointment
    }
  ],
  50: [ // StageValues.Holding
    {
      label: StepValueNames[StepValues.AppointmentScheduled],
      value: StepValues.AppointmentScheduled
    }
  ],
  60: [ // StageValues.Inactive
    {
      label: StepValueNames[StepValues.SetAsInactive],
      value: StepValues.SetAsInactive
    }
  ]
}

// object that lists for each stage/step being completed, what are the stages and step options available for creating a new step
export const NewStepMapping = {
  10: { //Stage - Request Prior Auth
    10: { // Step - Submit Rx Prior Auth
      stageOptions: [
        {
          label: StageValueNames[StageValues.RequestPriorAuth],
          value: StageValues.RequestPriorAuth,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SubmitHealthPriorAuth],
              value: StepValues.SubmitHealthPriorAuth
            }
          ]
        },
        {
          label: StageValueNames[StageValues.PriorAuthPending],
          value: StageValues.PriorAuthPending,
          stepOptions: [
            {
              label: StepValueNames[StepValues.ContactRxInsurance],
              value: StepValues.ContactRxInsurance
            },
            {
              label: StepValueNames[StepValues.SubmitAppeal],
              value: StepValues.SubmitAppeal
            },
            {
              label: StepValueNames[StepValues.SubmitAdditionalDocuments],
              value: StepValues.SubmitAdditionalDocuments
            },
            {
              label: StepValueNames[StepValues.AwaitingResponse],
              value: StepValues.AwaitingResponse
            }
          ]
        },
        {
          label: StageValueNames[StageValues.Inactive],
          value: StageValues.Inactive,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SetAsInactive],
              value: StepValues.SetAsInactive
            }
          ]
        }
      ]
    },
    20: { // Step - Submit Health Prior Auth
      stageOptions: [
        {
          label: StageValueNames[StageValues.PriorAuthPending],
          value: StageValues.PriorAuthPending,
          stepOptions: [
            {
              label: StepValueNames[StepValues.ContactHealthInsurance],
              value: StepValues.ContactHealthInsurance
            },
            {
              label: StepValueNames[StepValues.SubmitAppeal],
              value: StepValues.SubmitAppeal
            },
            {
              label: StepValueNames[StepValues.SubmitAdditionalDocuments],
              value: StepValues.SubmitAdditionalDocuments
            },
            {
              label: StepValueNames[StepValues.AwaitingResponse],
              value: StepValues.AwaitingResponse
            }
          ]
        },
        {
          label: StageValueNames[StageValues.Inactive],
          value: StageValues.Inactive,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SetAsInactive],
              value: StepValues.SetAsInactive
            }
          ]
        }
      ]
    }
  },
  20: { // Stage - Prior Auth Pending
    30: { // Step - Awaiting Response
      stageOptions: [
        {
          label: StageValueNames[StageValues.PriorAuthPending],
          value: StageValues.PriorAuthPending,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SubmitAppeal],
              value: StepValues.SubmitAppeal
            },
            {
              label: StepValueNames[StepValues.SubmitAdditionalDocuments],
              value: StepValues.SubmitAdditionalDocuments
            },
            {
              label: StepValueNames[StepValues.ContactPatient],
              value: StepValues.ContactPatient
            }
          ]
        },
        {
          label: StageValueNames[StageValues.BotoxProcurement],
          value: StageValues.BotoxProcurement,
          stepOptions: [
            {
              label: StepValueNames[StepValues.ContactPatient],
              value: StepValues.ContactPatient
            }
          ]
        },
        {
          label: StageValueNames[StageValues.RequestPriorAuth],
          value: StageValues.RequestPriorAuth,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SubmitHealthPriorAuth],
              value: StepValues.SubmitHealthPriorAuth
            }
          ]
        },
        {
          label: StageValueNames[StageValues.Inactive],
          value: StageValues.Inactive,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SetAsInactive],
              value: StepValues.SetAsInactive
            }
          ]
        }
      ]
    },
    40: { // Step - Contact Rx Insurance
      stageOptions: [
        {
          label: StageValueNames[StageValues.BotoxProcurement],
          value: StageValues.BotoxProcurement,
          stepOptions: [
            {
              label: StepValueNames[StepValues.ContactPatient],
              value: StepValues.ContactPatient
            }
          ]
        },
        {
          label: StageValueNames[StageValues.PriorAuthPending],
          value: StageValues.PriorAuthPending,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SubmitAppeal],
              value: StepValues.SubmitAppeal
            },
            {
              label: StepValueNames[StepValues.SubmitAdditionalDocuments],
              value: StepValues.SubmitAdditionalDocuments
            },
            {
              label: StepValueNames[StepValues.ContactPatient],
              value: StepValues.ContactPatient
            },
            {
              label: StepValueNames[StepValues.AwaitingResponse],
              value: StepValues.AwaitingResponse
            }
          ]
        },
        {
          label: StageValueNames[StageValues.RequestPriorAuth],
          value: StageValues.RequestPriorAuth,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SubmitHealthPriorAuth],
              value: StepValues.SubmitHealthPriorAuth
            }
          ]
        },
        {
          label: StageValueNames[StageValues.PriorAuthPending],
          value: StageValues.PriorAuthPending,
          stepOptions: [
            {
              label: StepValueNames[StepValues.ContactPatient],
              value: StepValues.ContactPatient
            },
            {
              label: StepValueNames[StepValues.AwaitingResponse],
              value: StepValues.AwaitingResponse
            }
          ]
        },
        {
          label: StageValueNames[StageValues.Inactive],
          value: StageValues.Inactive,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SetAsInactive],
              value: StepValues.SetAsInactive
            }
          ]
        }
      ]
    },
    50: { // Step - Contact Health Insurance
      stageOptions: [
        {
          label: StageValueNames[StageValues.BotoxProcurement],
          value: StageValues.BotoxProcurement,
          stepOptions: [
            {
              label: StepValueNames[StepValues.ContactPatient],
              value: StepValues.ContactPatient
            }
          ]
        },
        {
          label: StageValueNames[StageValues.RequestPriorAuth],
          value: StageValues.RequestPriorAuth,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SubmitAppeal],
              value: StepValues.SubmitAppeal
            },
            {
              label: StepValueNames[StepValues.SubmitAdditionalDocuments],
              value: StepValues.SubmitAdditionalDocuments
            },
            {
              label: StepValueNames[StepValues.ContactPatient],
              value: StepValues.ContactPatient
            }
          ]
        },
        {
          label: StageValueNames[StageValues.Inactive],
          value: StageValues.Inactive,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SetAsInactive],
              value: StepValues.SetAsInactive
            }
          ]
        }
      ]
    },
    60: { // Step - Submit Additional Documents
      stageOptions: [
        {
          label: StageValueNames[StageValues.BotoxProcurement],
          value: StageValues.BotoxProcurement,
          stepOptions: [
            {
              label: StepValueNames[StepValues.ContactPatient],
              value: StepValues.ContactPatient
            }
          ]
        },
        {
          label: StageValueNames[StageValues.RequestPriorAuth],
          value: StageValues.RequestPriorAuth,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SubmitHealthPriorAuth],
              value: StepValues.SubmitHealthPriorAuth
            }
          ]
        },
        {
          label: StageValueNames[StageValues.Inactive],
          value: StageValues.Inactive,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SetAsInactive],
              value: StepValues.SetAsInactive
            }
          ]
        }
      ]
    },
    70: { // Step - Submit Appeal
      stageOptions: [
        {
          label: StageValueNames[StageValues.BotoxProcurement],
          value: StageValues.BotoxProcurement,
          stepOptions: [
            {
              label: StepValueNames[StepValues.ContactPatient],
              value: StepValues.ContactPatient
            }
          ]
        },
        {
          label: StageValueNames[StageValues.RequestPriorAuth],
          value: StageValues.RequestPriorAuth,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SubmitHealthPriorAuth],
              value: StepValues.SubmitHealthPriorAuth
            }
          ]
        },
        {
          label: StageValueNames[StageValues.Inactive],
          value: StageValues.Inactive,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SetAsInactive],
              value: StepValues.SetAsInactive
            }
          ]
        }
      ]
    }
  },
  30: { // Stage - Botox Procurement
    80: { // Step - Contact Patient
      stageOptions: [
        {
          label: StageValueNames[StageValues.BotoxProcurement],
          value: StageValues.BotoxProcurement,
          stepOptions: [
            {
              label: StepValueNames[StepValues.ContactSpecialtyPharmacy],
              value: StepValues.ContactSpecialtyPharmacy
            }
          ]
        },
        {
          label: StageValueNames[StageValues.Inactive],
          value: StageValues.Inactive,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SetAsInactive],
              value: StepValues.SetAsInactive
            }
          ]
        }
      ]
    },
    90: { // Step - Contact Specialty Pharmacy
      stageOptions: [
        {
          label: StageValueNames[StageValues.BotoxProcurement],
          value: StageValues.BotoxProcurement,
          stepOptions: [
            {
              label: StepValueNames[StepValues.ContactPatient],
              value: StepValues.ContactPatient
            }
          ]
        },
        {
          label: StageValueNames[StageValues.ScheduleAppointments],
          value: StageValues.ScheduleAppointments,
          stepOptions: [
            {
              label: StepValueNames[StepValues.ScheduleAppointment],
              value: StepValues.ScheduleAppointment
            }
          ]
        },
        {
          label: StageValueNames[StageValues.Inactive],
          value: StageValues.Inactive,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SetAsInactive],
              value: StepValues.SetAsInactive
            }
          ]
        }
      ]
    }
  },
  40: { // Stage - Schedule Appointments
    100: {  // Step - Schedule Appointment
      stageOptions: [
        {
          label: StageValueNames[StageValues.Holding],
          value: StageValues.Holding,
          stepOptions: [
            {
              label: StepValueNames[StepValues.AppointmentScheduled],
              value: StepValues.AppointmentScheduled
            }
          ]
        },
        {
          label: StageValueNames[StageValues.Inactive],
          value: StageValues.Inactive,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SetAsInactive],
              value: StepValues.SetAsInactive
            }
          ]
        }
      ]
    }
  },
  50: {  // Stage - Holding
    120: {  // Step - Appointment Scheduled
      stageOptions: [
        {
          label: StageValueNames[StageValues.RequestPriorAuth],
          value: StageValues.RequestPriorAuth,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SubmitRxPriorAuth],
              value: StepValues.SubmitRxPriorAuth
            },
            {
              label: StepValueNames[StepValues.SubmitHealthPriorAuth],
              value: StepValues.SubmitHealthPriorAuth
            }
          ]
        },
        {
          label: StageValueNames[StageValues.BotoxProcurement],
          value: StageValues.BotoxProcurement,
          stepOptions: [
            {
              label: StepValueNames[StepValues.ContactSpecialtyPharmacy],
              value: StepValues.ContactSpecialtyPharmacy
            },
            {
              label: StepValueNames[StepValues.ContactPatient],
              value: StepValues.ContactPatient
            }
          ]
        },
        {
          label: StageValueNames[StageValues.ScheduleAppointments],
          value: StageValues.ScheduleAppointments,
          stepOptions: [
            {
              label: StepValueNames[StepValues.ScheduleAppointment],
              value: StepValues.ScheduleAppointment
            }
          ]
        },
        {
          label: StageValueNames[StageValues.Inactive],
          value: StageValues.Inactive,
          stepOptions: [
            {
              label: StepValueNames[StepValues.SetAsInactive],
              value: StepValues.SetAsInactive
            }
          ]
        }
      ]
    }
  }
}




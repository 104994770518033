import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { errorType as _errorType, successType as _successType, variantType as _variantType } from '../../constants/MessageConstants';
import '../../css/message.css';

/* Re-export constants for backwards compatibility. */
/** @deprecated use MessageConstants instead */
export const variantType = _variantType;
/** @deprecated use MessageConstants instead */
export const successType = _successType;
/** @deprecated use MessageConstants instead */
export const errorType = _errorType;

/**
 * Creates a message to pass to the message error function with the given error type
 * @param {string} errorType - @see errorType
 */
export function setErrorMessage(errorType) {
  return message.createMessageObject(`Unable to ${errorType ? errorType : 'perform action'} due to network issues.`, 'danger');
}

/**
 * Creates a message to pass to the message success function with the given success type
 * @param {any} successType
 */
export function setSuccessMessage(successType) {
  return message.createMessageObject(`${successType}.`, 'success');
}

/**
 * This is the object that users of the Message Display will use to add a message
 * Once the Message display component mounts, it will set the messageDisplayInstance
 * so this object can talk to the component.
 * 
 * If somehow you attemp to add a message before the messageDisplayInstance has been added,
 * no message will be shown or added.
 */
export const message = {
  /**
   * messageDisplayInstance
   * @private
   */
  messageDisplayInstance: null,
  /**
   * currentId
   * @private
   */
  currentId: 0,
  /**
   * Sets the next id
   * @private
   */
  get nextId() {
    return this.currentId++;
  },
  /**
   * Creates a message object whose proeprties are used for the alert
   * @param {string} message
   * @param {string} variant
   * @returns {number} messageId 
   */
  createMessageObject(message, variant) {
    const messageId = this.nextId;
    const messageObj = {
      id: messageId,
      variant: variant,
      text: message
    };
    this.add(messageObj);
    return messageId;
  },
  /**
   * Removes a message by Id
   * @param {number} messageId
   */
  dismiss(messageId) {
    if (messageId === undefined || messageId === null) return;
    this.remove(messageId);
  },
  /**
   * Removes all current messages
   */
  dismissAll() {
    this.remove();
  }
};

/**
 * The names in the array are methods that are on the MessageDisplay instance. We wrap them in another method
 * in case the MessageDisplay instance has not been defined yet so we won't blow up in that scenario.
 */
['add', 'remove'].forEach(method => {
  message[method] = (...args) => {
    if (message.messageDisplayInstance) message.messageDisplayInstance[method](...args);
  };
});

/**
 * Component which actually displays the messages
 */
export const MessageDisplay = withRouter(class extends Component {

  constructor(props) {
    super(props);

    this.state = {
      messages: []
    };

    this.removeHistoryListener = null;

    this.remove = this.remove.bind(this);
    this.add = this.add.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;

    const self = this;
    message.messageDisplayInstance = self;

    this.removeHistoryListener = history.listen((location, action) => {
      /**
       * Remove all messages when the route changes
       */
      self.remove();
    });
  }

  componentWillUnmount() {
    if (this.removeHistoryListener) this.removeHistoryListener();
  }

  /**
   * Adds the message object to state variable messages list
   * @param {object} messageObj
   */
  add(messageObj) {
    this.setState({
      messages: [messageObj, ...this.state.messages]
    });
  }

  /**
   * Remove a message from the state variable messages by Id
   * If id is undefined, then removes all messages from list
   * @param {number} id
   */
  remove(id) {
    this.setState({
      messages: this.state.messages.filter(m => id !== undefined && m.id !== id)
    });
  }

  render() {
    const { messages } = this.state;

    return (
      <React.Fragment>
        {messages.map(message => (
          <div key={message.id} className={`alert alert-${message.variant} alert__${message.variant} alert-dismissible fade show d-flex justify-content-between`} role="alert">
            {message.text}
            <button type="button" className="close-button" data-dismiss="alert" aria-label="Close" onClick={() => this.remove(message.id)}>
              <i className="pi pi-times"></i>
            </button>
          </div>
        ))}
      </React.Fragment>
    );
  }
});

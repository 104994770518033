import { StageStepStatusValues } from '../../constants/StageStepConstants';
import { getFormattedDateStr } from '../../scripts/helperFunctions';

export default class AddNewStageStepDto {
  constructor(options = {}) {
    this.patientId = options.patientId || 0;
    this.statusId = options.statusId || StageStepStatusValues.InProgress;
    this.stageId = options.stageId || 0;
    this.stepId = options.stepId || 0;
    this.offBoardReason = options.offBoardReason || '';
    this.offBoardOther = options.offBoardOther || '';
    this.offBoardResumeUtc = options.offBoardResumeUtc ? getFormattedDateStr(options.offBoardResumeUtc) : null; // convert utc string to only formatted local date
    this.appointmentDateUtc = options.appointmentDateUtc ? getFormattedDateStr(options.appointmentDateUtc) : null; // convert utc string to only formatted local date
    this.stepComment = options.stepComment || '';

    this.offBoardResume = this.offBoardResumeUtc !== null;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

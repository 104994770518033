import React, {useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';

import OfficeDisplay from './OfficeDisplay';
import OfficeDialog from './OfficeDialog';
import PageHeader from '../shared/PageHeader';

import api from '../../scripts/api';

import '../../css/offices.css';

const Offices = () => {
  const [offices, setOffices] = useState([]);
  const [currentOffice, setCurrentOffice] = useState(null);
  const [display, setDisplay] = useState(false);

  const getAllOffices = async () => {
    const [hasResult, results] = await api.getAllOffices(); // returns [] due to the is404Success flag in the api call

    if (hasResult) {
      setOffices(results.alloffices);
    } else {
      setOffices([]);
    }
  }

  useEffect(() => {
    getAllOffices();
  }, []);
  
  const createNew = () => {
    setDisplay(true);
  }

  const editOffice = (office) => {
    setCurrentOffice(office);
    setDisplay(true);
  }

  const closeEdit = (reload = false) => {
    setCurrentOffice(null);
    setDisplay(false);

    if (reload) {
      getAllOffices();
    }
  }

  const addOptions = {
    text: 'Add New Office',
    icon: 'pi pi-plus',
    command: createNew
  }

  return (
    <React.Fragment>
      <PageHeader title="Offices" btnOptions={addOptions} />
      <div className="container-fluid container__wide container-main offices-wrapper">

        <div className="row">
          <div className="col">
            {offices.map(office => <OfficeDisplay key={office.officeId} office={office} handleEdit={editOffice} />)}
          </div>
        </div>
      </div>

      <Dialog header={currentOffice ? 'Edit Office' : 'Add New Office'} footer={<div></div>} visible={display} style={{ width: '50vw' }} modal onHide={() => closeEdit()}>
        <OfficeDialog closeEdit={closeEdit} currentOffice={currentOffice} />
      </Dialog>
    </React.Fragment>
  );
}

export default Offices;



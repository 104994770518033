export default class EditPharmacyDto {
  constructor(options = {}) {
    this.id = options.pharmacyId || 0;
    this.pharmacyName = options.pharmacyName || '';
    this.phone = options.phone || '';
    this.isActive = options.hasOwnProperty('isActive') ? options.isActive : true;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

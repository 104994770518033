import SearchPatientResultDto from './SearchPatientResultDto';

export default class SearchPatientListDto {
  constructor(options = []) {
    this.patientresult = options.map(dto => new SearchPatientResultDto(dto));
  }

  stringify() {
    return JSON.stringify(this);
  }
}

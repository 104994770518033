import { getFormattedDateStr, dateDiffDays } from '../../scripts/helperFunctions';

export default class DashboardPatientDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.createdUtc = options.createdUtc ? getFormattedDateStr(options.createdUtc) : '';

    this.fullName = `${this.firstName} ${this.lastName}`;
    this.daysInStep = this.createdUtc ? `${dateDiffDays(new Date(this.createdUtc), new Date())} days` : 0;
  }


  stringify() {
    return JSON.stringify(this);
  }
}

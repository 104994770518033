import React from 'react'

export const NeedsRenewal = (props) => {

  return (
    <React.Fragment>
      {props.needsRenewal.map(patient => (
        <div className="row mb-2 dashboard__needs-renewal__patient-row" onClick={() => props.handleClick(patient.patientId)}>
          <div className="col-8">
            {patient.displayName}
          </div>
          <div className="col-4 d-flex justify-content-end">
            {`Expires: ${patient.expirationDateUtc}`}
          </div>
        </div>
      ))}

      <div className="row mt-3">
        <div className="col d-flex justify-content-end">
          <button className="button-primary" type="button" onClick={() => props.closeEdit()}>Close</button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NeedsRenewal;

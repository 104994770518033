import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

import { actionUserCreators } from './store/User';
import { useAuth0 } from './components/shared/Auth0';
import PrivateRoute from '../src/components/shared/PrivateRoute';
import Layout from './components/shared/Layout';
import HomeRedirect from './components/login/HomeRedirect';
import LoginCallback from './components/login/LoginCallback';
import { navigationUrls, LOGIN_REDIRECT_URL } from './constants';
import Dashboard from './components/dashboard/Dashboard';
import PatientStages from './components/stages/PatientStages';
import Users from './components/users/Users';
import Offices from './components/offices/Offices';
import Pharmacies from './components/pharmacies/Pharmacies';
import Insurance from './components/insurance/Insurance';
import Patient from './components/patient/Patient';

/*import './App.css';*/

function App(props) {
  const auth0Context = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth0Context.isAuthenticated) {
      dispatch(actionUserCreators.initializeUser());
    }
  }, [dispatch, auth0Context.isAuthenticated]);

  const rolePath = '/dashboard'; // todo: update this to the appropriate route when known

  const getTargetUrl = () => {
    const currentUrl = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    if (!window.location.search.includes('logoutReturn=')) {
      return currentUrl;
    }

    const nonce = window.localStorage.getItem('lastStateNonce');
    if (!nonce || !window.location.search.includes(`logoutReturn=${nonce}`)) {
      return currentUrl;
    }

    window.localStorage.removeItem('lastStateNonce');
    const state = JSON.parse(window.localStorage.getItem('lastStateValue'));
    window.localStorage.removeItem('lastStateValue');
    return state.targetUrl;
  }

  const decideRoute = () => {
    if (!auth0Context.loading && !auth0Context.isAuthenticated) {
      return <Route render={() => auth0Context.loginWithRedirect({ redirect_uri: window.origin, appState: { targetUrl: getTargetUrl() } })} />;
    } else {
      return (
        <React.Fragment>
          <Route path="/" render={(props) => <HomeRedirect {...props} rolePath={rolePath} />} exact />
          <Route path={LOGIN_REDIRECT_URL} render={(props) => <LoginCallback {...props} rolePath={rolePath} />} />
          <PrivateRoute.Dashboard path={navigationUrls.dashboard.URL} component={Dashboard} />
          <PrivateRoute.PatientStages path={navigationUrls.patientStages.URL} component={PatientStages} />
          <PrivateRoute.Users path={navigationUrls.users.URL} component={Users} />
          <PrivateRoute.Offices path={navigationUrls.offices.URL} component={Offices} />
          <PrivateRoute.HealthInsurers path={navigationUrls.healthInsurance.URL} component={Insurance} />
          <PrivateRoute.RxInsurers path={navigationUrls.rxInsurance.URL} component={Insurance} />
          <PrivateRoute.SpecialtyPharmacies path={navigationUrls.specialtyPharmacy.URL} component={Pharmacies} />
          <PrivateRoute.Patient path={`${navigationUrls.patient.URL}/:id([0-9]*)`} component={Patient} />
        </React.Fragment>
      );
    }
  };

  return (
    <Layout>
      {
        decideRoute()
      }
    </Layout>
  );
}

export default connect(
  state => state,
  dispatch => bindActionCreators({ ...actionUserCreators }, dispatch)
)(App);

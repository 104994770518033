export const getFormattedDateStr = (dateStr) => {
  if (!dateStr) {
    return '';
  }

  const date = new Date(dateStr);

  return getFormattedDate(date);
}

export const getFormattedDate = (date) => {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return month + '/' + day + '/' + year;
}

export const getLongDateStr = (dateStr) => {
  if (!dateStr) {
    return '';
  }

  const date = new Date(dateStr);

  return getLongDate(date);
}

const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
];

const longMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const getLongDate = (date) => {
  const year = date.getFullYear();
  const month = shortMonths[date.getMonth()];
  const day = date.getDate().toString().padStart(2, '0');

  return `${month} ${day} ${year}`;
}

export const getDashboardDate = (date) => {
  const year = date.getFullYear();
  const month = longMonths[date.getMonth()];
  const day = date.getDate().toString().padStart(2, '0');

  return `${month} ${day}, ${year}`;
}

export const dateDiffDays = (first, second) => {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

export const getFormErrorMessage = (errors, name) => {
  return errors[name] && <small className="p-error">{errors[name].message}</small>;
};

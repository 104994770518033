import InsuranceDetailDto from '../insurance/InsuranceDetailDto';
import PharmacyDetailDto from '../pharmacy/PharmacyDetailDto';

export default class InsuranceInfoDto {
  constructor(options = {}){
    this.activeHealthInsurance = options.activeHealthInsurance ? options.activeHealthInsurance.map(dto => new InsuranceDetailDto(dto)) : [];
    this.activeRxInsurance = options.activeRxInsurance ? options.activeRxInsurance.map(dto => new InsuranceDetailDto(dto)) : [];
    this.activePharmacies = options.activePharmacies ? options.activePharmacies.map(dto => new PharmacyDetailDto(dto)) : [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}

import React from 'react';
import { classNames } from 'primereact/utils';

import { MessageDisplay } from '../shared/Message';

import { StageValues } from '../../constants/StageStepConstants';

const PageHeader = (props) => {
  const buttonClasses = `button-primary header-button ${props.btnOptions && props.btnOptions.className ? props.btnOptions.className : ''}`;

  const options = props.stageQuery;

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="container-fluid container__wide container-header">
          <div className="row">
            <div className="col-8 d-flex align-items-center">
              <h2 className="no-margin page_title d-flex align-items-center">
                {props.title}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="stages__step-wrapper">
        <div className="container-fluid container__wide container-header">
          <div className="row">
            <div className="col-9 d-flex justify-content-between align-items-center">
              <div className={classNames('stages-link', { 'active': options.allStages })} onClick={props.setAllStages}>
                All Stages
              </div>
              <div className={classNames('stages-link', { 'active': options.stage === StageValues.RequestPriorAuth })}
                onClick={() => props.setStageValue(StageValues.RequestPriorAuth)}>
                Request PA
              </div>
              <div className={classNames('stages-link', { 'active': options.stage === StageValues.PriorAuthPending })}
                onClick={() => props.setStageValue(StageValues.PriorAuthPending)}>
                PA Pending
              </div>
              <div className={classNames('stages-link', { 'active': options.stage === StageValues.BotoxProcurement })}
                onClick={() => props.setStageValue(StageValues.BotoxProcurement)}>
                Botox
              </div>
              <div className={classNames('stages-link', { 'active': options.stage === StageValues.ScheduleAppointments })}
                onClick={() => props.setStageValue(StageValues.ScheduleAppointments)}>
                Appts
              </div>
              <div className={classNames('stages-link', { 'active': options.stage === StageValues.Holding })}
                   onClick={() => props.setStageValue(StageValues.Holding)}>
                Hold
              </div>
              <div className={classNames('stages-link', { 'active': options.stage === StageValues.Inactive })}
                   onClick={() => props.setStageValue(StageValues.Inactive)}>
                Inactive
              </div>
            </div>
            <div className="col-3 d-flex align-items-center justify-content-end">
              <button className={buttonClasses} onClick={props.btnOptions.command}>
                {props.btnOptions.iconSrc
                  ? <img src={props.btnOptions.iconSrc} alt={props.btnOptions.iconAlt} />
                  : <i className={props.btnOptions.icon}></i>
                }
                <span className="ps-3">{props.btnOptions.text}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid container__wide container-message">
        <MessageDisplay />
      </div>
    </React.Fragment>
  );
}

export default PageHeader;

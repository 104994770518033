////import { colors } from './constants/ColorConstants';

export const LOGIN_REDIRECT_URL = '/callback';

export const ADMIN_REQUEST_URL = '/admin/request';

export const userRoles = {
  SiteAdmin: 1,
  OrgAdmin: 2,
  OrgUser: 3
};

export const unknownRoleDisplay = 'Unknown';

export const eventTypes = {

}

export const insuranceTypes = {
  Insurance: 1,
  Pharmacy: 2
}

export const insuranceTypeNames = {
  1: 'Health',
  2: 'Rx'
}

export const insurancePathNames = {
  1: 'Health',
  2: 'Prescription',
  3: 'Buy And Bill'
}

export const navigationUrls = {
  dashboard: {
    URL: '/dashboard',
    DisplayText: 'Dashboard'
  },
  patientStages: {
    URL: '/patientstages',
    DisplayText: 'Patient Stages'
  },
  users: {
    URL: '/users',
    DisplayText: 'Users'
  },
  offices: {
    URL: '/offices',
    DisplayText: 'Offices'
  },
  healthInsurance: {
    URL: '/healthinsurance',
    DisplayText: 'Health Insurance'
  },
  rxInsurance: {
    URL: '/rxinsurance',
    DisplayText: 'RX Insurance'
  },
  specialtyPharmacy: {
    URL: '/specialtypharmacies',
    DisplayText: 'Specialty Pharmacies'
  },
  patient: {
    URL: '/patient',
    DisplayText: 'Patient Details'
  }
};

/*
 * Prime React Data Table 
 */
export const tableRowClassNames = {
  bottomBorder: 'bottom-border',
  expandedRow: 'js-expanded-row',
  expandableRow: 'js-expandable-row'
};

/**
 * function to set classnames to prime react data table rows
 * @param {any} selectorValue
 */
export function setInitialRowClassnames(selectorValue) {
  // get data table rows that have a class name that is not "js-expanded-row"
  const expandableRow = document.querySelectorAll(`${selectorValue ? selectorValue : '.p-datatable .p-datatable-wrapper'} tbody > tr[class]:not(.${tableRowClassNames.expandedRow})`);
  // add class names "js-expandable-row" and "bottom-border"
  expandableRow.forEach(row => {
    row.classList.add(`${tableRowClassNames.expandableRow}`, `${tableRowClassNames.bottomBorder}`);
  });
}

/**
 * function to toggle classnames on prime react data table rows
 * @param {any} selectorValue
 */
export function toggleRowClassname(selectorValue) {
  const tableBody = document.querySelector(`${selectorValue ? selectorValue : '.p-datatable .p-datatable-wrapper'} tbody`);

  // get all rows within the data table body that do not have a class attribute
  const expandedRowsNoClassname = tableBody.querySelectorAll(`${selectorValue ? selectorValue : '.p-datatable .p-datatable-wrapper'} tbody > tr:not([class])`);
  // add classnames to data table rows with no class attributes
  expandedRowsNoClassname.forEach(expandedRow => {
    expandedRow.classList.add(`${tableRowClassNames.expandedRow}`, `${tableRowClassNames.bottomBorder}`);
  });

  // get data table rows with class names "js-expanded-row" and "bottom-border"
  const expandedRowsWithClassname = tableBody.querySelectorAll(`${selectorValue ? selectorValue : '.p-datatable .p-datatable-wrapper'} tbody > tr[class="${tableRowClassNames.expandedRow} ${tableRowClassNames.bottomBorder}"]`);
  // if the element before the table row element has class name "js-expandable-row", remove "bottom-border"
  expandedRowsWithClassname.forEach(expandedRow => {
    if (expandedRow.previousElementSibling.classList.contains(`${tableRowClassNames.expandableRow}`)) {
      expandedRow.previousElementSibling.classList.remove(`${tableRowClassNames.bottomBorder}`);
    }
  });
}

export const pagingOptions = {
  DEFAULT_PAGE_NUMBER: 1,
  DEFAULT_PAGE_SIZE: 10,
  INVENTORY_DEFAULT_PAGE_SIZE: 75
};

export const sortOrder = {
  ASCENDING: 1,
  DESCENDING: 2
};

export const stepSortCol = {
  STEP_ID: 1,
  APPOINTMENT_DATE: 2
};

export const HTTP_NOT_FOUND = 404;

export const HTTP_BAD_REQUEST = 400;

export const HTTP_CONFLICT = 409;

export const errorMessages = {
  sampleError: 'This is an error description'
};

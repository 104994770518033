import React from 'react';
import { Controller } from 'react-hook-form';
import { InputMask } from 'primereact/inputmask';
import { classNames } from 'primereact/utils';

import { getFormErrorMessage } from '../../../scripts/helperFunctions';
import { StepValues } from '../../../constants/StageStepConstants'

const Botox = ({ errors, control, getValues, doValidations }) => {

  const getStepForm = () => {
    const stepId = getValues('stepId');

    if (stepId === StepValues.ContactPatient) {
      return (
        <div className="row mb-3">
          <div className="col-6">
            <label htmlFor="patientContactedDateUtc" className={classNames('field-label', { 'p-error': errors.patientContactedDateUtc })}>Date Contacted</label>
            <Controller name="patientContactedDateUtc" control={control}
              rules={doValidations
                ? {
                  required: 'Date Contacted is required',
                  pattern: { value: /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/i, message: 'Invalid date format e.g. mm/dd/yyyy' }
                }
                : null
              }
              render={({ field, fieldState }) => (
                <InputMask id={field.patientContactedDateUtc} mask="99/99/9999" placeholder="mm/dd/yyyy" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
              )} />
            {getFormErrorMessage(errors, 'patientContactedDateUtc')}
          </div>
        </div>
      );
    } else if (stepId === StepValues.ContactSpecialtyPharmacy) {
      return (
        <div className="row mb-3">
          <div className="col-6">
            <label htmlFor="botoxContactDateUtc" className={classNames('field-label', { 'p-error': errors.botoxContactDateUtc })}>Date Contacted</label>
            <Controller name="botoxContactDateUtc" control={control}
              rules={doValidations
                ? {
                  required: 'Date Contacted is required',
                  pattern: { value: /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/i, message: 'Invalid date format e.g. mm/dd/yyyy' }
                }
                : null
              }
              render={({ field, fieldState }) => (
                <InputMask id={field.botoxContactDateUtc} mask="99/99/9999" placeholder="mm/dd/yyyy" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
              )} />
            {getFormErrorMessage(errors, 'botoxContactDateUtc')}
          </div>

          <div className="col-6">
            <label htmlFor="botoxDeliveryDateUtc" className={classNames('field-label', { 'p-error': errors.botoxDeliveryDateUtc })}>Botox Delivery Date</label>
            <Controller name="botoxDeliveryDateUtc" control={control}
              rules={doValidations
                ? {
                  required: 'Botox Delivery Date is required',
                  pattern: { value: /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/i, message: 'Invalid date format e.g. mm/dd/yyyy' }
                }
                : null
              }
              render={({ field, fieldState }) => (
                <InputMask id={field.botoxDeliveryDateUtc} mask="99/99/9999" placeholder="mm/dd/yyyy" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
              )} />
            {getFormErrorMessage(errors, 'botoxDeliveryDateUtc')}
          </div>
        </div>
      );
    }

    return null;
  }

  return getStepForm();
}

export default Botox;

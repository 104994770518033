import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';

import SetAsInactive from './addSteps/SetAsInactive';
import AppointmentScheduled from './addSteps/AppointmentScheduled';

import { getFormErrorMessage } from '../../scripts/helperFunctions';
import { StageStepMapping, NewStepMapping, StageValues, StageValueNames } from '../../constants/StageStepConstants';
import api from '../../scripts/api';
import { message } from '../shared/Message';
import { variantType } from '../../constants/MessageConstants';

import SaveStageStepDto from '../../DTOs/stageStep/SaveStageStepDto';
import AddNewStageStepDto from '../../DTOs/stageStep/AddNewStageStepDto';

import PlusSquare from '../../images/plus-square.png';

const getStepValueOptions = () => {
  return Object.values(StageValues).map(val => ({
    value: val,
    label: StageValueNames[val]
  }));
}

const NewStep = (props) => {
  const [showComments, setShowComments] = useState(false);
  const [selectedStageId, setSelectedStageId] = useState(0);

  const defaultValues = props.stepInfo;

  const { control, watch, formState: { errors }, handleSubmit, getValues, setValue } = useForm({ shouldFocusError: false, defaultValues });
  const watchStageId = watch('stageId');


  const onSubmit = async (data) => {
    console.log(data);

    const formData = {
      ...data,
      patientId: props.patientId
    }

    let reloadPage = false;

    try {
      const dto = new SaveStageStepDto({
        currentStepDto: props.completeStepInfo,
        addNewStepDto: new AddNewStageStepDto(formData)
      });

      await api.saveStageStep(dto);
      reloadPage = true;
    } catch (e) {
      console.log(e.payload);
      const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
      message.createMessageObject(msg, variantType.error);
    }

    props.closeEdit(reloadPage);
  }

  const toggleComments = () => {
    setShowComments(!showComments);
  }

  const selectStageId = (stageId, callback) => {
    setValue('stageId', stageId, { shouldDirty: true });
    setValue('stepId', 0, { shouldDirty: true });
    setSelectedStageId(stageId);

    callback(stageId);
  }

  let newStageOptions;
  let newStepOptions;

  if (props.completeStepInfo.stageId === StageValues.Inactive) {
    // completing the Inactive stage - all stages/steps should be available
    newStageOptions = getStepValueOptions();
    newStepOptions = selectedStageId === 0 ? {} : StageStepMapping[selectedStageId];
  } else {
    const newOptions = NewStepMapping[props.completedStageId][props.completedStepId].stageOptions;
    newStageOptions = newOptions.map(option => ({ label: option.label, value: option.value }));
    newStepOptions = selectedStageId === 0 ? {} : newOptions.filter(option => option.value === selectedStageId)[0].stepOptions;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
      <div className="row mb-3">
        <div className="col-6">
          <label htmlFor="stageId" className={classNames('field-label', { 'p-error': errors.stageId })}>Stage</label>
          <Controller name="stageId" control={control}
            rules={{ min: { value: 1, message: 'Stage is required' } }}
            render={({ field, fieldState }) => (
              <Dropdown id={field.stageId}
                options={newStageOptions}
                placeholder="-- select --"
                {...field}
                className={classNames({ 'p-invalid': fieldState.invalid })}
                onChange={(e) => selectStageId(e.value, field.onChange)} />
            )} />
          {getFormErrorMessage(errors, 'stageId')}
        </div>
        <div className="col-6">
          <label htmlFor="stepId" className={
            classNames('field-label',
              { 'field-label__disabled': selectedStageId === 0 },
              { 'p-error': errors.stepId }
            )}>Step</label>
          <Controller name="stepId" control={control}
            rules={{ min: { value: 1, message: 'Step is required' } }}
            render={({ field, fieldState }) => (
              <Dropdown id={field.stepId}
                options={newStepOptions}
                placeholder="-- select --" {...field}
                disabled={selectedStageId === 0}
                className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          {getFormErrorMessage(errors, 'stepId')}
        </div>
      </div>

      {watchStageId === StageValues.Inactive &&
        <SetAsInactive errors={errors} control={control} watch={watch} />
      }

      {watchStageId === StageValues.Holding &&
        <AppointmentScheduled errors={errors} control={control} />
      }

      <div className="row mb-3">
        <div className="col d-flex justify-content-end" onClick={() => toggleComments()}>
          <div className="add-comment me-4">
            <img src={PlusSquare} alt="Add comments" />
          </div>
          <div className="add-comment__text">
            Add/Edit Comments
          </div>
        </div>
      </div>

      {
        showComments &&
        <div className="row">
          <div className="col">
            <label htmlFor="stepComment" className="field-label">Comment</label>
            <Controller name="stepComment" control={control} render={({ field }) => (
              <InputTextarea id={field.stepComment} placeholder="Step Comment" {...field} />
            )} />
          </div>
        </div>
      }

      <div className="row mt-4">
        <div className="col-6 d-flex justify-content-start">
          <button type="button" className="button-cancel"
            onClick={() => props.handleBack({ ...props.stepInfo, ...getValues() })}>Back</button>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <button className="button-primary" type="submit">Save</button>
        </div>
      </div>

    </form>
  );
}

export default NewStep;

import React from 'react';
import { Controller } from 'react-hook-form';
import { InputMask } from 'primereact/inputmask';
import { InputSwitch } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';

import { getFormErrorMessage } from '../../../scripts/helperFunctions';

const PADenied = ({ errors, control, doValidations }) => {

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col-6">
          <label htmlFor="paDenialDateUtc" className={classNames('field-label', { 'p-error': errors.paDenialDateUtc })}>PA Denial Date</label>
          <Controller name="paDenialDateUtc" control={control}
            rules={doValidations
              ? {
                required: 'PA Denial Date is required',
                pattern: { value: /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/i, message: 'Invalid date format e.g. mm/dd/yyyy' }
              }
              : null
            }
            render={({ field, fieldState }) => (
              <InputMask id={field.paDenialDateUtc} mask="99/99/9999" placeholder="mm/dd/yyyy" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          {getFormErrorMessage(errors, 'paDenialDateUtc')}
        </div>
      </div>
    </React.Fragment>
  );
}

export default PADenied;

import StageStepDisplayDto from './StageStepDisplayDto';

export default class PatientStageListResultDto {
  constructor(options = {}) {
    this.patientStages = options.patientStages ? options.patientStages.map(dto => new StageStepDisplayDto(dto)) : [];
    this.hasMore = options.hasOwnProperty('hasMore') ? options.hasMore : false;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

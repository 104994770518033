import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';

import PharmacyDisplay from './PharmacyDisplay';
import PharmacyDialog from './PharmacyDialog';
import PageHeader from '../shared/PageHeader';

import api from '../../scripts/api';

import '../../css/pharmacies.css';

const Pharmacies = () => {
  const [pharmacies, setPharmacies] = useState([]);
  const [currentPharmacy, setCurrentPharmacy] = useState(null);
  const [display, setDisplay] = useState(false);

  const getAllPharmacies = async () => {
    const [hasResult, result] = await api.getAllPharmacies(); // returns [] due to the is404Success flag in the api call

    if (hasResult) {
      setPharmacies(result.allpharmacies);
    } else {
      setPharmacies([]);
    }
  }

  useEffect(() => {
    getAllPharmacies();
  }, []);

  const createNew = () => {
    setDisplay(true);
  }

  const editPharmacy = (pharmacy) => {
    setCurrentPharmacy(pharmacy);
    setDisplay(true);
  }

  const closeEdit = (reload = false) => {
    setCurrentPharmacy(null);
    setDisplay(false);

    if (reload) {
      getAllPharmacies();
    }
  }

  const addOptions = {
    text: 'Add New Pharmacy',
    icon: 'pi pi-plus',
    command: createNew,
    className: 'pharmacies__new-button'
  }

  return (
    <React.Fragment>
      <PageHeader title="Specialty Pharmacies" btnOptions={addOptions} />
      <div className="container-fluid container__wide container-main pharmacies-wrapper">

        <div className="row">
          <div className="col">
            {pharmacies.map(pharmacy => <PharmacyDisplay key={pharmacy.pharmacyId} pharmacy={pharmacy} handleEdit={editPharmacy} />)}
          </div>
        </div>
      </div>

      <Dialog header={currentPharmacy ? 'Edit Pharmacy' : 'Add New Pharmacy'} footer={<div></div>} visible={display} style={{ width: '50vw' }} modal onHide={() => closeEdit()}>
        <PharmacyDialog closeEdit={closeEdit} currentPharmacy={currentPharmacy} />
      </Dialog>
    </React.Fragment>
  );
}

export default Pharmacies;



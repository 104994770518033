import { getFormattedDateStr } from '../../scripts/helperFunctions';

export default class EditPatientDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.dateOfBirthUtc = options.dateOfBirthUtc ? getFormattedDateStr(options.dateOfBirthUtc) : ''; // convert utc string to only formatted local date
    this.emailAddress = options.emailAddress || '';
    this.primaryPhone = options.primaryPhone || '';
    this.isActive = options.hasOwnProperty('isActive') ? options.isActive : true;
    this.healthInsuranceId = options.healthInsuranceId || 0;
    this.healthInsurancePhone = options.healthInsurancePhone || '';
    this.rxInsuranceId = options.rxInsuranceId || 0;
    this.rxInsurancePhone = options.rxInsurancePhone || '';
    this.specialtyPharmacyId = options.specialtyPharmacyId || 0;
    this.homeOfficeId = options.homeOfficeId || 0;
    this.patientComments = options.patientComments || '';
    this.insuranceComments = options.insuranceComments || '';
    this.botoxOne = options.hasOwnProperty('botoxOne') ? options.botoxOne : false;
    this.buyAndBill = options.hasOwnProperty('buyAndBill') ? options.buyAndBill : false;
    this.insurancePathId = options.insurancePathId || null;
  }

  toJSON() {
    const data = { ...this };
    data.dateOfBirthUtc = new Date(this.dateOfBirthUtc).toISOString();

    return data;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

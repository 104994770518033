import OfficeDetailDto from './OfficeDetailDto';

export default class OfficeListDto {
  constructor(options = []) {
    this.alloffices = options.map(dto => new OfficeDetailDto(dto));
  }

  stringify() {
    return JSON.stringify(this);
  }
}

import React, { Component } from "react";
import { Redirect } from 'react-router';
import { Auth0Context } from '../shared/Auth0';

class LoginCallback extends Component {
  render() {
    const { rolePath } = this.props;
    const { isAuthenticated } = this.context;

    if (isAuthenticated === true) {
      return <Redirect to={rolePath} />;
    } else if (isAuthenticated === false) {
      return <Redirect to="/" />;
    }

    return <div>Loading...</div>;
  }
}

LoginCallback.contextType = Auth0Context;

export default LoginCallback;

import React from 'react'
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';

import { message } from '../shared/Message';
import api from '../../scripts/api';
import { variantType } from '../../constants/MessageConstants';

import EditInsuranceDto from '../../DTOs/insurance/EditInsuranceDto';


const InsuranceDialog = (props) => {
  const defaultValues = props.currentInsurance || {
    insuranceName: '',
    isActive: true
  }

  const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    console.log(data);

    const insuranceId = props.currentInsurance ? props.currentInsurance.insuranceId : 0;
    const insuranceData = new EditInsuranceDto({ insuranceId: insuranceId, ...data });  // must cast the data object into a dto so stringify is available

    try {
      if (insuranceId > 0) {
        await api.editInsurance(insuranceId, insuranceData);
      } else {
        insuranceData.insuranceType = props.insuranceType;
        await api.createInsurance(insuranceData);
      }
      
    } catch (e) {
      console.log(e.payload);
      const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
      message.createMessageObject(msg, variantType.error);
    }

    props.closeEdit(true);
  };

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
      <div className="mb-2">
        <label htmlFor="insuranceName" className={classNames('field-label', { 'p-error': errors.insuranceName })}>Insurance Name</label>
        <Controller name="insuranceName" control={control} rules={{ required: 'Insurance Name is required' }} render={({ field, fieldState }) => (
          <InputText id={field.insuranceName} placeholder="New Insurance" {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
        )} />
        {getFormErrorMessage('insuranceName')}
      </div>

      <div className="row mb-2">
        <div className="col-6">
          <div className="field-label">Status</div>
          <Controller name="isActive" control={control}
            render={({ field: { onChange, value } }) => (
              <div className="d-flex">
                <label htmlFor="isActive" className="me-3 switch-label">Active</label>
                <InputSwitch id="isActive" checked={value} onChange={onChange} />
              </div>
            )} />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6 d-flex justify-content-start">
          <button type="button" className="button-cancel" onClick={() => props.closeEdit()}>Cancel</button>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <button className="button-primary" type="submit">{props.currentInsurance ? 'Save Insurance' : 'Add New Insurance'}</button>
        </div>
      </div>
    </form >
  );
}

export default InsuranceDialog;

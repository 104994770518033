import React from 'react';

import { MessageDisplay } from '../shared/Message';

const PageHeader = (props) => {
  const buttonClasses = `button-primary header-button ${props.btnOptions && props.btnOptions.className ? props.btnOptions.className : ''}`;

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="container-fluid container__wide container-header">
          <div className="row">
            <div className="col-8 d-flex align-items-center">
              <h2 className="no-margin page_title d-flex align-items-center">
                {props.title}
              </h2>
            </div>
            <div className="col-4 d-flex justify-content-end">
              {props.btnOptions &&
                <button className={buttonClasses} onClick={props.btnOptions.command}>
                  <i className={props.btnOptions.icon}></i>
                  <span className="ps-3">{props.btnOptions.text}</span>
                </button>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid container__wide container-message">
        <MessageDisplay />
      </div>
    </React.Fragment>
  );
}

export default PageHeader;

import PatientDetailsDto from './PatientDetailsDto';
import StageStepDisplayDto from '../stageStep/StageStepDisplayDto';
import PreAuthorizationDto from '../priorAuthorization/PreAuthorizationDto';

export default class PatientDto {
  constructor(options = {}) {
    this.patientDetails = new PatientDetailsDto(options.patientDetails);
    this.activeSteps = options.activeSteps ? options.activeSteps.map(dto => new StageStepDisplayDto(dto)) : [];
    this.completedSteps = options.completedSteps ? options.completedSteps.map(dto => new StageStepDisplayDto(dto)) : [];
    this.priorAuthorization = options.priorAuthorization ? new PreAuthorizationDto(options.priorAuthorization) : null;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';

import InsuranceDisplay from './InsuranceDisplay';
import InsuranceDialog from './InsuranceDialog';
import PageHeader from '../shared/PageHeader';

import api from '../../scripts/api';
import { insuranceTypes, insuranceTypeNames } from '../../constants';

import '../../css/insurance.css';

const getInsuranceType = (props) => {
  return props.location.pathname.includes('health') ? insuranceTypes.Insurance : insuranceTypes.Pharmacy;
}

const Insurance = (props) => {
  const [insurance, setInsurance] = useState([]);
  const [currentInsurance, setCurrentInsurance] = useState(null);
  const [display, setDisplay] = useState(false);
  const [insuranceType] = useState(getInsuranceType(props));

  const getAllInsurance = async () => {
    const [hasResult, results] = await api.getAllInsurance(insuranceType); // returns [] due to the is404Success flag in the api call

    if (hasResult) {
      setInsurance(results.allinsurance);
    } else {
      setInsurance([]);
    }
  }

  useEffect(() => {
    getAllInsurance();
  }, []);

  const createNew = () => {
    setDisplay(true);
  }

  const editInsurance = (insurance) => {
    setCurrentInsurance(insurance);
    setDisplay(true);
  }

  const closeEdit = (reload = false) => {
    setCurrentInsurance(null);
    setDisplay(false);

    if (reload) {
      getAllInsurance();
    }
  }

  const addOptions = {
    text: 'Add New Insurance',
    icon: 'pi pi-plus',
    command: createNew,
    className: 'insurance__new-button'
  }

  return (
    <React.Fragment>
      <PageHeader title={`${insuranceTypeNames[insuranceType]} Insurance`} btnOptions={addOptions} />
      <div className="container-fluid container__wide container-main insurance-wrapper">

        <div className="row">
          <div className="col">
            {insurance.map(insurance => <InsuranceDisplay key={insurance.insuranceId} insurance={insurance} handleEdit={editInsurance} />)}
          </div>
        </div>
      </div>

      <Dialog header={currentInsurance ? 'Edit Insurance' : 'Add New Insurance'} footer={<div></div>} visible={display} style={{ width: '50vw' }} modal onHide={() => closeEdit()}>
        <InsuranceDialog closeEdit={closeEdit} currentInsurance={currentInsurance} insuranceType={insuranceType} />
      </Dialog>
    </React.Fragment>
  );
}

export default Insurance;



import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { InputMask } from 'primereact/inputmask';
import { Tooltip } from 'primereact/tooltip';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';

import { getFormErrorMessage } from '../../scripts/helperFunctions';
import api from '../../scripts/api';

import PlusSquare from '../../images/plus-square.png';

const PatientInfo = (props) => {
  const [showComments, setShowComments] = useState(false);
  const [activeOffices, setActiveOffices] = useState([]);

  const defaultValues = props.patientInfo;

  const { control, formState: { errors }, handleSubmit } = useForm({ shouldFocusError: false, defaultValues });

  useEffect(() => {
    const getActiveOffices = async () => {
      const [hasResult, result] = await api.getActiveOffices(); // returns [] due to the is404Success flag in the api call

      if (hasResult) {
        setActiveOffices(result.alloffices);
      } else {
        setActiveOffices([]);
      }
    }

    getActiveOffices();
  }, []);

  const onSubmit = (data) => {
    console.log(data);

    props.handleNext({ ...props.patientInfo, ...data });
  }

  const toggleComments = () => {
    setShowComments(!showComments);
  }

  const offices = activeOffices.map(x => ({ label: x.officeName, value: x.officeId }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

      <div className="row mb-3">
        <div className="col-6">
          <label htmlFor="firstName" className={classNames('field-label', { 'p-error': errors.firstName })}>First Name</label>
          <Controller name="firstName" control={control} rules={{ required: 'First Name is required' }} render={({ field, fieldState }) => (
            <InputText id={field.firstName} placeholder="First Name" {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
          )} />
          {getFormErrorMessage(errors, 'firstName')}
        </div>
        <div className="col-6">
          <label htmlFor="lastName" className={classNames('field-label', { 'p-error': errors.lastName })}>Last Name</label>
          <Controller name="lastName" control={control} rules={{ required: 'Last Name is required' }} render={({ field, fieldState }) => (
            <InputText id={field.lastName} placeholder="Last Name" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
          )} />
          {getFormErrorMessage(errors, 'lastName')}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <label htmlFor="dateOfBirthUtc" className={classNames('field-label', { 'p-error': errors.dateOfBirthUtc })}>Date of Birth</label>
          <Controller name="dateOfBirthUtc" control={control}
            rules={{
              required: 'Date of Birth is required',
              pattern: { value: /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/i, message: 'Invalid date format e.g. mm/dd/yyyy' }
            }}
            render={({ field, fieldState }) => (
              <InputMask id={field.dateOfBirthUtc} mask="99/99/9999" placeholder="mm/dd/yyyy" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          {getFormErrorMessage(errors, 'dateOfBirthUtc')}
        </div>
        <div className="col-6">
          <label htmlFor="primaryPhone" className={classNames('field-label', { 'p-error': errors.primaryPhone })}>Phone Number</label>
          <Controller name="primaryPhone" control={control}
            rules={{
              required: 'Phone Number is required',
              pattern: { value: /^\(\d{3}\) \d{3}-\d{4}$/i, message: 'Invalid format e.g. (999) 999-9999' }
            }} render={({ field, fieldState }) => (
              <InputMask id={field.primaryPhone} mask="(999) 999-9999" placeholder="(999) 999-9999" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          {getFormErrorMessage(errors, 'primaryPhone')}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <label htmlFor="emailAddress" className={classNames('field-label', { 'p-error': errors.emailAddress })}>Email</label>
          <Controller name="emailAddress" control={control}
            rules={{
              required: 'Email is required',
              pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address E.g. example@email.com' }
            }}
            render={({ field, fieldState }) => (
              <InputText id={field.emailAddress} placeholder="Email e.g. john@abc.com" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          {getFormErrorMessage(errors, 'emailAddress')}
        </div>
        <div className="col-6">
          <label htmlFor="homeOfficeId" className={classNames('field-label', { 'p-error': errors.homeOfficeId })}>Primary Office</label>
          <Controller name="homeOfficeId" control={control}
            rules={{ min: { value: 1, message: 'Primary Office is required' } }}
            render={({ field, fieldState }) => (
              <Dropdown id={field.homeOfficeId} options={offices} placeholder="-- select --" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          {getFormErrorMessage(errors, 'homeOfficeId')}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <Controller name="botoxOne" control={control}
            render={({ field: { onChange, value } }) => (
              <React.Fragment>
                <div className="field-label">
                  BotoxOne Account
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="botoxOneYes" name="role" value={true} onChange={onChange} checked={value === true} />
                  <label htmlFor="botoxOneYes" className="ms-3 radio-label">Yes</label>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="botoxOneNo" name="role" value={false} onChange={onChange} checked={value === false} />
                  <label htmlFor="botoxOneNo" className="ms-3 radio-label">No</label>
                </div>
              </React.Fragment>
            )} />
        </div>
        <div className="col-6">
          <div className="field-label">Buy and Bill</div>
          <Controller name="buyAndBill" control={control} render={({ field: { onChange, value } }) => (
            <div className="mt-2 d-flex align-items-center">
              <Checkbox inputId="buyAndBill" onChange={onChange} checked={value === true} />
              <label htmlFor="buyAndBill" className="ms-3 radio-label">Yes</label>
            </div>
          )} />
        </div>
      </div>

      <div className="row mb-3">
        <div className="offset-6 col-6 d-flex justify-content-end" onClick={() => toggleComments()}>
          <div className="add-comment me-4">
            <img src={PlusSquare} alt="Add comments" />
          </div>
          <div className="add-comment__text">
            Add/Edit Comments
          </div>
        </div>
      </div>

      {showComments &&
        <div className="row">
          <div className="col">
            <label htmlFor="patientComments" className="field-label">Comments</label>
            <Controller name="patientComments" control={control} render={({ field, fieldState }) => (
              <InputTextarea id={field.patientComments} placeholder="Patient Comments" {...field} />
            )} />
          </div>
        </div>
      }

      <div className="row mt-4">
        <div className="col-6 d-flex justify-content-start">
          <button type="button" className="button-cancel" onClick={() => props.closeEdit()}>Cancel</button>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <button className="button-primary" type="submit">Next</button>
        </div>
      </div>
    </form >
  );
}

export default PatientInfo;

import React from 'react'
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { InputSwitch } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';

import { message } from '../shared/Message';
import { userRoles } from '../../constants';
import api from '../../scripts/api';
import { variantType } from '../../constants/MessageConstants';

import EditUserDto from '../../DTOs/User/EditUserDto';


const UserDialog = (props) => {
  const defaultValues = props.currentUser || {
    firstName: '',
    lastName: '',
    emailAddress: '',
    role: userRoles.OrgUser,
    isActive: true
  }

  const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    console.log(data);

    const userId = props.currentUser ? props.currentUser.userId : 0;
    const userData = new EditUserDto({ userId: userId, ...data });  // must cast the data object into a dto so stringify is available

    try {
      if (userId > 0) {
        await api.editUser(userId, userData);
      } else {
        await api.createUser(userData);
      }
      
    } catch (e) {
      console.log(e.payload);
      const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
      message.createMessageObject(msg, variantType.error);
    }

    props.closeEdit(true);
  };

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
      <div className="mb-2">
        <label htmlFor="firstName" className={classNames('field-label', { 'p-error': errors.firstName })}>First Name</label>
        <Controller name="firstName" control={control} rules={{ required: 'First Name is required' }} render={({ field, fieldState }) => (
          <InputText id={field.firstName} placeholder="First Name" {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
        )} />
        {getFormErrorMessage('firstName')}
      </div>

      <div className="mb-2">
        <label htmlFor="lastName" className={classNames('field-label', { 'p-error': errors.lastName })}>Last Name</label>
        <Controller name="lastName" control={control} rules={{ required: 'Last Name is required' }} render={({ field, fieldState }) => (
          <InputText id={field.lastName} placeholder="Last Name" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
        )} />
        {getFormErrorMessage('lastName')}
      </div>

      <div className="mb-3">
        <label htmlFor="emailAddress" className={classNames('field-label', {'p-error': errors.emailAddress })}>Email</label>
        <Controller name="emailAddress" control={control}
          rules={{ required: 'Email is required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address E.g. example@email.com' } }}
          render={({ field, fieldState }) => (
            <InputText id={field.emailAddress} placeholder="Email e.g. john@abc.com" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
          )} />
        {getFormErrorMessage('emailAddress')}
      </div>

      <div className="row mb-2">
        <div className="col-6">
          <Controller name="role" control={control}
            render={({ field: { onChange, value } }) => (
              <React.Fragment>
                <div className="field-label">Role</div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="role1" name="role" value={userRoles.OrgUser} onChange={onChange} checked={value === userRoles.OrgUser} />
                  <label htmlFor="role1" className="ms-3 radio-label">User</label>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="role2" name="role" value={userRoles.OrgAdmin} onChange={onChange} checked={value === userRoles.OrgAdmin} />
                  <label htmlFor="role2" className="ms-3 radio-label">Admin</label>
                </div>
              </React.Fragment>
            )} />
        </div>
        <div className="col-6">
          <div className="field-label">Status</div>
          <Controller name="isActive" control={control}
            render={({ field: { onChange, value } }) => (
              <div className="d-flex">
                <label htmlFor="isActive" className="me-3 switch-label">Active</label>
                <InputSwitch id="isActive" checked={value} onChange={onChange} />
              </div>
            )} />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6 d-flex justify-content-start">
          <button type="button" className="button-cancel" onClick={() => props.closeEdit()}>Cancel</button>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <button className="button-primary" type="submit">{props.currentUser ? 'Save User' : 'Add New User'}</button>
        </div>
      </div>
    </form >
  );
}

export default UserDialog;

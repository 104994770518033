import { LOGIN_REDIRECT_URL } from '../constants';
import { Lock } from './Lock';

const createAuth0Client = window.createAuth0Client;

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const redirect_uri = window.location.origin + LOGIN_REDIRECT_URL;
const leeway = 300; // should be no need to set this to 5 minutes but Auth0 is acting screwy

let auth0Client;
const headerLock = new Lock();

const init = async () => {
  return await createAuth0Client({
    domain,
    client_id,
    audience,
    redirect_uri,
    leeway
  });
};

export default {
  getAuth0Client() {
    if (!auth0Client) {
      auth0Client = init();
    }
    return auth0Client;
  },

  async getHeaders() {
    const auth0Client = await this.getAuth0Client();
    try {
      // Prevent several getHeaders requests from happening at the same time
      // (This reduces the number of auth0 API calls and failures on iOS)
      await headerLock.acquire();
      let token;
      for (let i = 0; i < 5; i++) {
        try {
          // This call still fails roughly 1 in every 10 tries on iOS
          token = await auth0Client.getTokenSilently();
          break;
        } catch (e) {
          if (e.error !== 'login_required') {
            throw e;
          }
          const time = i * 250;
          await new Promise(resolve => {
            window.setTimeout(resolve, time);
          });
        }
      }
      if (!token) {
        throw 'Login retry failure';
      }
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${token}`);
      headers.append('Content-Type', 'application/json');
      return headers;
    } finally {
      headerLock.release();
    }
  }
}

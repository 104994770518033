import React, { useRef } from 'react';
import { Card } from 'primereact/card';
import { Menu } from 'primereact/menu';

import More from '../../images/more-vertical.png';

const OfficeDisplay = ({ office, handleEdit }) => {
  const menu = useRef(null);

  const menuItems = [
    {
      label: 'Edit Office',
      icon: 'pi pi-pencil',
      command: (event) => {
        handleEdit(office);
      }
    }
  ];

  return (
    <Card className="custom-card__index custom-card__no-padding">
      <div className="row">
        <div className="col-10 flex-column">
          <div className="office-card__name">{office.officeName}</div>
        </div>
        <div className="d-flex col-2 justify-content-end">
          {office.isActive &&
            <div className="custom-card__active">
              Active
            </div>
          }

          <Menu model={menuItems} popup ref={menu} id="action_menu" />
          <div className="action-menu__button" onClick={(event) => menu.current.toggle(event)} aria-controls="action-menu" aria-haspopup>
            <img src={More} alt="more menu" />
          </div>

        </div>
      </div>
    </Card>
  );
}

export default OfficeDisplay;

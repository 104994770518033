export default class PatientStagesQueryDto {
  constructor(options = []) {
    this.allStages = options.hasOwnProperty('allStages') ? options.allStages : true;
    this.stage = options.stage || null;
    this.stepSortCol = options.stepSortCol || null;
    this.stepSortDir = options.stepSortDir || null;
    this.stepFilter = options.stepFilter || null;
    this.currentPage = options.currentPage || 0;
    this.pageLength = options.pageLength || 50;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

import { apiCall } from './api.base.js';

import InsuranceListDto from '../DTOs/insurance/InsuranceListDto';

export default {
  /**
   * Requests system Insurance' info
   * @param {Object} dto
   * @returns {Promise<[hasResult: boolean, result: InsuranceListDto]>} - @see InsuranceListDto
   */
  getAllInsurance(insuranceType) {
    return apiCall(`insurance?insuranceType=${insuranceType}`, {
      responseType: InsuranceListDto,
      is404Success: true
    });
  },

  /**
   * Create Insurance
   * @param {any} dto
   */
  createInsurance(dto) {
    return apiCall('insurance', { method: 'POST', body: dto });
  },

  /**
   * Edit Insurance
   * @param {any} dto
   */
  editInsurance(id, dto) {
    return apiCall(`insurance/${id}`, { method: 'PUT', body: dto });
  }
}

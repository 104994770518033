import React, { useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Menu } from 'primereact/menu';
import { Dialog } from 'primereact/dialog';

import { insurancePathNames } from '../../constants';

import More from '../../images/more-vertical.png';
import Calendar from '../../images/calendar.png';
import Phone from '../../images/phone.png';
import Email from '../../images/mail.png';
import Home from '../../images/home.png';
import Eye from '../../images/view-small.png';

import { getFormattedDateStr } from '../../scripts/helperFunctions'

const PatientDetails = (props) => {
  const [display, setDisplay] = useState(false);
  const [comment, setComment] = useState();
  const [commentTitle, setCommentTitle] = useState();
  const menu = useRef(null);

  const menuItems = [
    {
      label: 'Edit Patient',
      icon: 'pi pi-pencil',
      command: (event) => {
        props.editPatient();
      }
    }
  ];

  const showPatientComments = () => {
    setComment(props.currentPatient.patientComments);
    setCommentTitle('Patient Comments');
    setDisplay(true);
  }

  const showInsuranceComments = () => {
    setComment(props.currentPatient.insuranceComments);
    setCommentTitle('Insurance Comments');
    setDisplay(true);
  }

  const closeEdit = () => {
    setDisplay(false);
    setComment(null);
    setCommentTitle(null);
  }

  return (
    <React.Fragment>
      <Card className="patient-card">
        <div>
          <div className="d-flex justify-content-between">

            {props.currentPatient.insurancePathId > 0
              ? <div className="patient-path">
                <div className="patient-path__text">
                  {insurancePathNames[props.currentPatient.insurancePathId]}
                </div>
              </div>
              : <div></div>
            }

            <div>
              <Menu model={menuItems} popup ref={menu} id="action_menu" />
              <div className="action-menu__button" onClick={(event) => menu.current.toggle(event)} aria-controls="action-menu" aria-haspopup>
                <img src={More} alt="more menu" />
              </div>
            </div>
          </div>
        </div>

        <div className="patient-card__name patient-card__row">
          {props.currentPatient.firstName} {props.currentPatient.lastName}
        </div>

        {props.currentPatient.isActive &&
          <div className="patient-card__active d-flex">
            <div className="patient-card__active-dot"></div>
            <div className="patient-card__active ps-2">Active</div>
          </div>
        }

        <div className="patient-card__header">
          Contact Details
        </div>

        <div className="patient-card__row">
          <img src={Calendar} alt="Date of Birth" className="me-3" />
          {getFormattedDateStr(props.currentPatient.dateOfBirthUtc)}
        </div>

        <div className="patient-card__row">
          <img src={Phone} alt="Phone" className="me-3" />
          {props.currentPatient.primaryPhone}
        </div>

        <div className="patient-card__row">
          <img src={Email} alt="Email" className="me-3" />
          {props.currentPatient.emailAddress}
        </div>

        <div className="patient-card__row">
          <img src={Home} alt="Home Office" className="me-3" />
          {props.currentPatient.homeOfficeName}
        </div>

        <div className="patient-card__row">
          <img src={Eye} alt="View Patient Comments" className="me-3" />
          <span className="patient-card__comment-link" onClick={showPatientComments}>View Patient Comments</span>
        </div>

        <div className="patient-card__header">
          Insurance Information
        </div>

        <div className="patient-card__row d-flex align-items-center">
          <div>
            <img src={Phone} alt="Health Insurance" className="me-3" />
          </div>
          <div>
            <div className="patient-car__detail-label">
              Insurance
            </div>
            <strong>
              {props.currentPatient.healthInsuranceName}
            </strong>
            <br />
            {props.currentPatient.healthInsurancePhone}
          </div>
        </div>

        <div className="patient-card__row d-flex align-items-center">
          <div>
            <img src={Phone} alt="Rx Insurance" className="me-3" />
          </div>
          <div>
            <div className="patient-car__detail-label">
              Rx Insurance
            </div>
            <strong>
              {props.currentPatient.rxInsuranceName}
            </strong>
            <br />
            {props.currentPatient.rxInsurancePhone}
          </div>
        </div>

        <div className="patient-card__row d-flex align-items-center">
          <div>
            <img src={Phone} alt="Specialty Pharmacy" className="me-3" />
          </div>
          <div>
            <div className="patient-car__detail-label">
              Specialty Pharmacy
            </div>
            <strong>
              {props.currentPatient.specialtyPharmacyName}
            </strong>
            <br />
            {props.currentPatient.specialtyPharmacyPhone}
          </div>
        </div>

        <div className="patient-card__row">
          <img src={Eye} alt="View Insurance Comments" className="me-3" />
          <span className="patient-card__comment-link" onClick={showInsuranceComments}>View Insurance Comments</span>
        </div>

      </Card>

      <Dialog header={commentTitle} footer={<div></div>} visible={display} style={{ width: '50vw' }} modal onHide={() => closeEdit()}>
        <React.Fragment>
          <div className="row patient-step__wrapper">
            <div className="col d-flex">
              {comment}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col d-flex justify-content-end">
              <button type="button" className="button-primary" onClick={closeEdit}>Close</button>
            </div>
          </div>
        </React.Fragment>
      </Dialog>

    </React.Fragment>
  );
}

export default PatientDetails;

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';

import { getFormErrorMessage } from '../../scripts/helperFunctions';
import { StageValues, StageValueNames, StageStepMapping } from '../../constants/StageStepConstants';
import api from '../../scripts/api';
import { message } from '../shared/Message';
import { variantType } from '../../constants/MessageConstants';

import AddNewStageStepDto from '../../DTOs/stageStep/AddNewStageStepDto';

import PlusSquare from '../../images/plus-square.png';

const getStepValueOptions = () => {
  return Object.values(StageValues).map(val => ({
    value: val,
    label: StageValueNames[val]
  }));
}

const NewStepDialog = (props) => {
  const [showComments, setShowComments] = useState(false);
  const [selectedStageId, setSelectedStageId] = useState(0);

  const defaultValues = { stageId: null, stepId: null };

  const { control, formState: { errors }, handleSubmit, getValues, setValue } = useForm({ shouldFocusError: false, defaultValues });

  const onSubmit = async (data) => {
    console.log(data);

    const formData = {
      ...data,
      patientId: props.patientId
    }

    let reloadPage = false;

    try {
      const dto = new AddNewStageStepDto(formData);

      await api.newStageStep(dto);
      reloadPage = true;
    } catch (e) {
      console.log(e.payload);
      const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
      message.createMessageObject(msg, variantType.error);
    }

    props.closeEdit(reloadPage);
  }

  const toggleComments = () => {
    setShowComments(!showComments);
  }

  const selectStageId = (stageId) => {
    setValue('stageId', stageId, { shouldDirty: true });
    setSelectedStageId(stageId);
  }

  const newStageOptions = getStepValueOptions();
  const newStepOptions = selectedStageId === 0 ? {} : StageStepMapping[selectedStageId];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
      <div className="row mb-3">
        <div className="col-6">
          <label htmlFor="stageId" className={classNames('field-label', { 'p-error': errors.stageId })}>Stage</label>
          <Controller name="stageId" control={control}
            rules={{ min: { value: 1, message: 'Stage is required' } }}
            render={({ field, fieldState }) => (
              <Dropdown id={field.stageId}
                options={newStageOptions}
                placeholder="-- select --"
                {...field}
                className={classNames({ 'p-invalid': fieldState.invalid })}
                onChange={(e) => selectStageId(e.value)} />
            )} />
          {getFormErrorMessage(errors, 'stageId')}
        </div>
        <div className="col-6">
          <label htmlFor="stepId" className={
            classNames('field-label',
              { 'field-label__disabled': selectedStageId === 0 },
              { 'p-error': errors.stepId }
            )}>Step</label>
          <Controller name="stepId" control={control}
            rules={{ min: { value: 1, message: 'Step is required' } }}
            render={({ field, fieldState }) => (
              <Dropdown id={field.stepId}
                options={newStepOptions}
                placeholder="-- select --" {...field}
                disabled={selectedStageId === 0}
                className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          {getFormErrorMessage(errors, 'stageId')}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col d-flex justify-content-end" onClick={() => toggleComments()}>
          <div className="add-comment me-4">
            <img src={PlusSquare} alt="Add comments" />
          </div>
          <div className="add-comment__text">
            Add/Edit Comments
          </div>
        </div>
      </div>

      {
        showComments &&
        <div className="row">
          <div className="col">
            <label htmlFor="stepComment" className="field-label">Comment</label>
            <Controller name="stepComment" control={control} render={({ field }) => (
              <InputTextarea id={field.stepComment} placeholder="Step Comment" {...field} />
            )} />
          </div>
        </div>
      }

      <div className="row mt-4">
        <div className="col-6 d-flex justify-content-start">
          <button type="button" className="button-cancel"
            onClick={props.closeEdit}>Cancel</button>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <button className="button-primary" type="submit">Save</button>
        </div>
      </div>

    </form>
  );
}

export default NewStepDialog;

import React from 'react';
import { Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';

import { getFormErrorMessage } from '../../../scripts/helperFunctions';
import { InsurancePathValues } from '../../../constants/StageStepConstants';

const RequestPriorAuth = ({ errors, control, doValidations }) => {

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col-6">
          <label htmlFor="howSubmitted" className={classNames('field-label', { 'p-error': errors.howSubmitted })}>How Submitted</label>
          <Controller name="howSubmitted" control={control}
            rules={doValidations
              ? { required: 'How Submitted is required' }
              : null
            }
            render={({ field, fieldState }) => (
              <InputText id={field.howSubmitted} placeholder="How Submitted" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          {getFormErrorMessage(errors, 'howSubmitted')}
        </div>
        <div className="col-6">
          <label htmlFor="dateSubmittedUtc" className={classNames('field-label', { 'p-error': errors.dateSubmittedUtc })}>Date Submitted</label>
          <Controller name="dateSubmittedUtc" control={control}
            rules={doValidations
              ? {
                required: 'Date Submitted is required',
                pattern: { value: /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/i, message: 'Invalid date format e.g. mm/dd/yyyy' }
              }
              : null
            }
            render={({ field, fieldState }) => (
              <InputMask id={field.dateSubmittedUtc} mask="99/99/9999" placeholder="mm/dd/yyyy" {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          {getFormErrorMessage(errors, 'dateSubmittedUtc')}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <Controller name="insurancePathId" control={control}
            render={({ field: { onChange, value } }) => (
              <React.Fragment>
                <div className="field-label">
                  Insurance Path
                  <span style={{ fontWeight: 'normal' }}>&nbsp;(optional)</span>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="pathRx" name="path" value={InsurancePathValues.Prescription} onChange={onChange} checked={value === InsurancePathValues.Prescription} />
                  <label htmlFor="pathRx" className="ms-3 radio-label">Pharmacy</label>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="pathHealth" name="path" value={InsurancePathValues.Health} onChange={onChange} checked={value === InsurancePathValues.Health} />
                  <label htmlFor="pathHealth" className="ms-3 radio-label">Medical</label>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <RadioButton inputId="pathBuy" name="path" value={InsurancePathValues.BuyAndBill} onChange={onChange} checked={value === InsurancePathValues.BuyAndBill} />
                  <label htmlFor="pathBuy" className="ms-3 radio-label">Buy and Bill</label>
                </div>
              </React.Fragment>
            )} />
        </div>
      </div>

    </React.Fragment>
  );
}

export default RequestPriorAuth;

import { apiCall } from './api.base.js';

import UserListDto from '../DTOs/User/UserListDto';

export default {
  /**
   * Requests system users' info
   * @param {Object} dto
   * @returns {Promise<[hasResult: boolean, result: UserListDto]>} - @see UserListDto
   */
  getAllUsers() {
    return apiCall('user', {
      responseType: UserListDto,
      is404Success: true
    });
  },

  /**
   * Create User
   * @param {any} dto
   */
  createUser(dto) {
    return apiCall('user', { method: 'POST', body: dto });
  },

  /**
   * Edit User
   * @param {any} dto
   */
  editUser(id, dto) {
    return apiCall(`user/${id}`, { method: 'PUT', body: dto });
  }
}

import { getFormattedDateStr } from '../../scripts/helperFunctions';

export default class NewPatientDto {
  constructor(options = {}) {
    // patient info
    this.id = 0;
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.dateOfBirthUtc = options.dateOfBirthUtc ? getFormattedDateStr(options.dateOfBirthUtc) : ''; // convert utc string to only formatted local date
    this.emailAddress = options.emailAddress || '';
    this.primaryPhone = options.primaryPhone || '';
    this.isActive = options.hasOwnProperty('isActive') ? options.isActive : true;
    this.healthInsuranceId = options.healthInsuranceId || 0;
    this.healthInsurancePhone = options.healthInsurancePhone || '';
    this.rxInsuranceId = options.rxInsuranceId || 0;
    this.rxInsurancePhone = options.rxInsurancePhone || '';
    this.specialtyPharmacyId = options.specialtyPharmacyId || 0;
    this.homeOfficeId = options.homeOfficeId || 0;
    this.patientComments = options.patientComments || '';
    this.insuranceComments = options.insuranceComments || '';
    this.botoxOne = options.hasOwnProperty('botoxOne') ? options.botoxOne : true;
    this.buyAndBill = options.hasOwnProperty('buyAndBill') ? options.botoxOne : false;
    this.insurancePathId = options.insurancePathId || null;
    // step and stage info
    this.statusId = options.statusId || 0;
    this.stageId = options.stageId || 0;
    this.stepId = options.stepId || 0;
    this.howSubmitted = options.howSubmitted || '';
    this.dateSubmittedUtc = options.dateSubmittedUtc ? getFormattedDateStr(options.dateSubmittedUtc) : ''; // convert utc string to only formatted local date
    this.stepComment = options.stepComment || '';
  }

  toJSON() {
    const data = { ...this };
    data.dateOfBirthUtc = this.dateOfBirthUtc ? new Date(this.dateOfBirthUtc).toISOString() : null;
    data.dateSubmittedUtc = data.dateSubmittedUtc ? new Date(this.dateSubmittedUtc).toISOString() : null;

    return data;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

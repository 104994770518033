import { apiCall } from './api.base.js';

import PatientStageListResultDto from '../DTOs/stageStep/PatientStageListResultDto';
import EditStageStepDto from '../DTOs/stageStep/EditStageStepDto';

export default {
  /**
   * Searches patients by first/last name
   * @param {Object} dto
   * @returns {Promise<PatientStageListResultDto>} - @see PatientStageListResultDto
   */
  searchPatientStages(dto) {
    return apiCall(`stages/search`, {
      method: 'POST',
      body: dto,
      responseType: PatientStageListResultDto
    });
  },

  /**
   * Get StageStep info
   * @param {Object} dto
   * @returns {Promise<StageSTepDto>} - @see StageStepDto
   */
  getStageStep(stageStepId) {
    return apiCall(`stages?stageStepId=${stageStepId}`, {
      responseType: EditStageStepDto
    });
  },

  /**
   * Save StageStep info
   * @param {Object} dto
   * @returns {Promise<int>}
   */
  saveStageStep(dto) {
    return apiCall(`stages`, {
      method: 'POST',
      body: dto
    });
  },

  /**
   * New StageStep
   * @param {Object} dto
   * @returns {Promise<int>}
   */
  newStageStep(dto) {
    return apiCall(`stages/new`, {
      method: 'POST',
      body: dto
    });
  }
}

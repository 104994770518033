import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { Auth0Context } from './Auth0';
import { userRoles } from '../../constants';

class PrivateRoute extends React.Component {

  static contextType = Auth0Context;

  render() {
    const { component: Component, render, promptLogin, allowedRoles, userRoles, userLoading, ...rest } = this.props;
    const { isAuthenticated, loading, loginWithRedirect } = this.context;

    // To determine if the user can access this route, we first look at the Auth0Context 'loading'
    // property to determine if Auth0 is still performing its checks to see if there is a current logged
    // in user.  This is for both when the user was logged in and returning to the site and also for when 
    // the user is logging in and we are being redirected back to the site with a 'code=...' part in the URL
    // which is to be used by Auth0.  If we are loading, then we don't render anything yet as we are waiting.
    //
    // If Auth0 is not loading, then we either render the component if the user isAuthenticated, or a Redirect component
    // back to the home page or open the login prompt if the user is not authenticated.
    return (
      <Route {...rest} render={(props) => {
        const homeRedirect = <Redirect to={{
          pathname: '/',
          state: { from: props.location }
        }} />;

        let retVal;

        if (loading || userLoading) { // If loading, don't render anything and wait
          retVal = null;
        } else if (isAuthenticated) { // If they are authenticated, render the component

          if (userRoles.some(role => allowedRoles.includes(role))) { // is the user allowed to visit this 'page'
            retVal = Component ? <Component {...props} /> : render(props);
          } else { // User not allowed to be here, redirect to home
            retVal = homeRedirect;
          }

        } else { // If they are not authenticated, either redirect or open the login prompt

          if (promptLogin) {
            loginWithRedirect({
              appState: {
                targetUrl: props.match.url // don't need origin since react router will be handling this, not window.history
              }
            });
          } else {
            retVal = homeRedirect;
          }
        }

        // ReSharper disable once UsageOfPossiblyUnassignedValue
        return retVal;
      }} />
    );
  }
}

PrivateRoute.defaultProps = {
  allowedRoles: ['']
};

const mapStateToProps = (state) => {
  return {
    userRoles: state.user.roles,
    userLoading: state.user.initializing
  };
};

const ConnectedPrivateRoute = connect(
  mapStateToProps
)(PrivateRoute);

const allRoles = [
  userRoles.SiteAdmin,
  userRoles.OrgAdmin,
  userRoles.OrgUser
];

const adminRoles = [
  userRoles.SiteAdmin,
  userRoles.OrgAdmin
];

export const Dashboard = props => <ConnectedPrivateRoute
  {...props}
  const allowedRoles={allRoles} />;

export const PatientStages = props => <ConnectedPrivateRoute
  {...props}
  const allowedRoles={allRoles} />;

export const Users = props => <ConnectedPrivateRoute
  {...props}
  const allowedRoles={adminRoles} />;

export const Offices = props => <ConnectedPrivateRoute
  {...props}
  const allowedRoles={adminRoles} />;

export const HealthInsurers = props => <ConnectedPrivateRoute
  {...props}
  const allowedRoles={adminRoles} />;

export const RxInsurers = props => <ConnectedPrivateRoute
  {...props}
  const allowedRoles={adminRoles} />;

export const SpecialtyPharmacies = props => <ConnectedPrivateRoute
  {...props}
  const allowedRoles={adminRoles} />;

export const Patient = props => <ConnectedPrivateRoute
  {...props}
  const allowedRoles={allRoles} />;

export default {
  Dashboard,
  PatientStages,
  Users,
  Offices,
  HealthInsurers,
  RxInsurers,
  SpecialtyPharmacies,
  Patient
};

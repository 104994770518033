/**
 * variant type text
 */
export const variantType = {
  error: 'danger',
  success: 'success',
  warning: 'warning'
};

/**
 * Error type text
 */
export const errorType = {
};

/**
 * Success type text
 */
export const successType = {
};

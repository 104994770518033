import { getFormattedDateStr } from '../../scripts/helperFunctions';
import StepCommentDto from './StepCommentDto';

export default class EditStageStepDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.statusId = options.statusId || 0;
    this.patientId = options.patientId || 0;
    this.stageId = options.stageId || 0;
    this.stepId = options.stepId || 0;
    this.howSubmitted = options.howSubmitted || '';
    this.dateSubmittedUtc = options.dateSubmittedUtc ? getFormattedDateStr(options.dateSubmittedUtc) : ''; // convert utc string to only formatted local date
    this.botoxContactDateUtc = options.botoxContactDateUtc ? getFormattedDateStr(options.botoxContactDateUtc) : ''; // convert utc string to only formatted local date
    this.botoxDeliveryDateUtc = options.botoxDeliveryDateUtc ? getFormattedDateStr(options.botoxDeliveryDateUtc) : ''; // convert utc string to only formatted local date
    this.appointmentDateUtc = options.appointmentDateUtc ? getFormattedDateStr(options.appointmentDateUtc) : ''; // convert utc string to only formatted local date
    this.patientContactedDateUtc = options.patientContactedDateUtc ? getFormattedDateStr(options.patientContactedDateUtc) : ''; // convert utc string to only formatted local date
    this.offBoardReason = options.offBoardReason || '';
    this.offBoardOther = options.offBoardOther || '';
    this.offBoardResumeUtc = options.offBoardResumeUtc ? getFormattedDateStr(options.offBoardResumeUtc) : null; // convert utc string to only formatted local date
    this.comments = options.comments ? options.comments.map(dto => new StepCommentDto(dto)) : [];
    this.insurancePathId = options.insurancePathId || null;
    this.stepComment = options.stepComment || '';

    // from patient record
    this.isActive = options.isActive || true;

    // from current prior auth record
    this.priorAuthDecisionId = options.priorAuthDecisionId || null;
    this.paDenialDateUtc = options.paDenialDateUtc ? getFormattedDateStr(options.paDenialDateUtc) : ''; // convert utc string to only formatted local date
    this.paNumber = options.paNumber || '';
    this.paStartDateUtc = options.paStartDateUtc ? getFormattedDateStr(options.paStartDateUtc) : ''; // convert utc string to only formatted local date
    this.paEndDateUtc = options.paEndDateUtc ? getFormattedDateStr(options.paEndDateUtc) : ''; // convert utc string to only formatted local date

    // computed
    this.addNewStep = true; // used by the Complete Dialog flow
    this.offBoardResume = this.offBoardResumeUtc !== null;
  }

  toJSON() {
    const data = { ...this };
    data.dateSubmittedUtc = this.dateSubmittedUtc ? new Date(this.dateSubmittedUtc).toISOString() : null;
    data.botoxContactDateUtc = this.botoxContactDateUtc ? new Date(this.botoxContactDateUtc).toISOString() : null;
    data.botoxDeliveryDateUtc = this.botoxDeliveryDateUtc ? new Date(this.botoxDeliveryDateUtc).toISOString() : null;
    data.appointmentDateUtc = this.appointmentDateUtc ? new Date(this.appointmentDateUtc).toISOString() : null;
    data.patientContactedDateUtc = this.patientContactedDateUtc ? new Date(this.patientContactedDateUtc).toISOString() : null;
    data.paDenialDateUtc = this.paDenialDateUtc ? new Date(this.paDenialDateUtc).toISOString() : null;
    data.paStartDateUtc = this.paStartDateUtc ? new Date(this.paStartDateUtc).toISOString() : null;
    data.paEndDateUtc = this.paEndDateUtc ? new Date(this.paEndDateUtc).toISOString() : null

    return data;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

import { getFormattedDateStr } from '../../scripts/helperFunctions';
import { DecisionTypeNames } from '../../constants/StageStepConstants';

export default class PreAuthorizationDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.decisionId = options.decisionId || 0;
    this.paNumber = options.paNumber || '';
    this.paStartDateUtc = options.paStartDateUtc ? getFormattedDateStr(options.paStartDateUtc) : '';
    this.paEndDateUtc = options.paEndDateUtc ? getFormattedDateStr(options.paEndDateUtc) : '';

    this.decisionText = this.decisionId > 0 ? DecisionTypeNames[this.decisionId] : '';
  }

  stringify() {
    return JSON.stringify(this);
  }
}

export default class UserInfoDto {
  constructor(options = {}) {
    this.id = options.applicationUserId || 0;
    this.externalId = options.externalId || '';
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.email = options.emailAddress || '';
    this.roles = options.roles && options.roles.length > 0 ? options.roles : [];
    this.fullName = `${this.firstName} ${this.lastName}`;
  }

  stringify() {
    return JSON.stringify(this);
  }
}

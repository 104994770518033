import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';

import DashboardCard from './DashboardCard';
import NeedsRenewal from './NeedsRenewal';
import { MessageDisplay } from '../shared/Message';
import LoadingSpinner from '../shared/LoadingSpinner';

import DashboardDto from '../../DTOs/dashboard/DashboardDto';

import { navigationUrls } from '../../constants';
import { StageValues, StageValueNames } from '../../constants/StageStepConstants';
import { getDashboardDate } from '../../scripts/helperFunctions';
import api from '../../scripts/api';
import { variantType } from '../../constants/MessageConstants';
import { message } from '../shared/Message';

import '../../css/dashboard.css';

const Dashboard = (props) => {
  const [dashboardData, setDashboardData] = useState(new DashboardDto());
  const [isLoading, setIsLoading] = useState(true);
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        const result = await api.getDashboardData();
        setDashboardData(result);
      } catch (e) {
        const msg = e.payload && e.payload.title ? e.payload.title : 'Something went wrong';
        message.createMessageObject(msg, variantType.error);
      } finally {
        setIsLoading(false);
      }
    }

    getDashboardData();
  }, []);

  const headerClick = (stageValue) => {
    localStorage.setItem('dashboardStage', stageValue);
    props.history.push(navigationUrls.patientStages.URL);
  }

  const displayDialog = () => {
    setDisplay(true);
  }

  const closeEdit = () => {
    setDisplay(false);
  }

  const openPatient = (patientId) => {
    props.history.push(`${navigationUrls.patient.URL}/${patientId}`);
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="container-fluid container__wide container-header">
          <div className="row">
            <div className="col-8 d-flex align-items-center">
              <h2 className="no-margin page_title d-flex align-items-center">
                Welcome back, {props.fullName}!
              </h2>
            </div>
            <div className="col-4 d-flex align-items-center justify-content-end dashboard-date">
              Last Updated:&nbsp;
              {getDashboardDate(new Date())}
            </div>
          </div>
          {dashboardData.needsRenewal.length > 0 &&
            <div className="row">
              <div className="col dashboard__needs-renewal">
                {`You have ${dashboardData.needsRenewal.length} patients who need Prior Auth Renewal in the next 45 days. `}
                <span className="dashboard__needs-renewal__view-all" onClick={displayDialog}>View all</span>
              </div>
            </div>
          }
        </div>
      </div>

      <div className="container-fluid container__wide container-message">
        <MessageDisplay />
      </div>

      <div className="container-fluid container__wide container-main dashboard-wrapper">
        <div className="row mb-5">
          <div className="col-6">
            <DashboardCard cardCount={dashboardData.requestPriorAuthCount} cardTitle={StageValueNames[StageValues.RequestPriorAuth]}
              headerClick={() => headerClick(StageValues.RequestPriorAuth)} cardNames={dashboardData.requestPriorAuth} />
          </div>
          <div className="col-6">
            <DashboardCard cardCount={dashboardData.priorAuthPendingCount} cardTitle={StageValueNames[StageValues.PriorAuthPending]}
              headerClick={() => headerClick(StageValues.PriorAuthPending)} cardNames={dashboardData.priorAuthPending} />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-6">
            <DashboardCard cardCount={dashboardData.botoxProcurementCount} cardTitle={StageValueNames[StageValues.BotoxProcurement]}
              headerClick={() => headerClick(StageValues.BotoxProcurement)} cardNames={dashboardData.botoxProcurement} />
          </div>
          <div className="col-6">
            <DashboardCard cardCount={dashboardData.ongoingAppointmentsCount} cardTitle={StageValueNames[StageValues.ScheduleAppointments]}
              headerClick={() => headerClick(StageValues.ScheduleAppointments)} cardNames={dashboardData.ongoingAppointments} />
          </div>
        </div>
      </div>

      <Dialog header="Patients who need Prior Auth Renewal" footer={<div></div>} visible={display} style={{ width: '50vw' }} modal onHide={() => closeEdit()}>
        <NeedsRenewal closeEdit={closeEdit} needsRenewal={dashboardData.needsRenewal} handleClick={openPatient} />
      </Dialog>

    </React.Fragment>
  );
};

export default withRouter(connect(
  state => ({ fullName: state.user.fullName }),
)(Dashboard));

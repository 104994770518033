import React from 'react';
import { withRouter } from 'react-router-dom';

import ArrowRight from '../../images/arrow-right.png'

const DashboardCard = (props) => {

  const setPatient = (patientId) => {
    props.history.push(`/patient/${patientId}`);
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-end">
        <div className="d-flex justify-content-center align-items-center db-card__count">{props.cardCount}</div>
      </div>
      <div className="db-card__wrapper">
        <div className="db-card__header" onClick={props.headerClick}>
          {props.cardTitle}
          <img src={ArrowRight} alt={props.cardTitle} className="db-card__arrow" />
        </div>
        <div className="db-card__body">
          {props.cardNames.length > 0
            ? <React.Fragment>
              {props.cardNames.map(name => (
                <div key={name.id} className="mb-2 row db-card__patient-name" onClick={(e) => setPatient(name.id)}>
                  <div className="col-10">
                    {name.fullName}
                  </div>
                  <div className="col-2">
                    {name.daysInStep}
                  </div>
                </div>
              ))}
              <div className="mb-2 row">
                <div className="col-2 offset-10 db-card__view-all" onClick={props.headerClick}>
                  view all
                </div>
              </div>
            </React.Fragment>
            : <div>No patients are in this stage</div>
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(DashboardCard);
